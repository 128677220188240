import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientItem, SiteItem } from "types/client.type";
import { FilterProps, ListPaginated } from "types/list.type";

type ClientState = {
  clients: ListPaginated<ClientItem>;
  clientFilter: FilterProps;
  sites: ListPaginated<SiteItem>;
  siteFilter: FilterProps;
};

const initialState: ClientState = {
  clients: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  sites: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  clientFilter: {
    page: 1,
    perpage: 10,
  },
  siteFilter: {
    page: 1,
    perpage: 10,
  },
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClients(state, action: PayloadAction<ListPaginated<ClientItem>>) {
      state.clients = action.payload;
    },
    setSites(state, action: PayloadAction<ListPaginated<SiteItem>>) {
      state.sites = action.payload;
    },
    setClientFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.clientFilter = payload;
    },
    setSiteFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.siteFilter = payload;
    },
  },
});

export default clientSlice.reducer;

export const { setClients, setSites, setClientFilter, setSiteFilter } =
  clientSlice.actions;

const getClientReducer = (state: any): ClientState => state.client;

export const selectClients = createSelector(
  getClientReducer,
  ({ clients }) => clients
);

export const selectSites = createSelector(
  getClientReducer,
  ({ sites }) => sites
);

export const selectClientFilter = createSelector(
  getClientReducer,
  ({ clientFilter }) => clientFilter
);

export const selectSiteFilter = createSelector(
  getClientReducer,
  ({ siteFilter }) => siteFilter
);
