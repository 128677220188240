// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const ReportingNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "CA",
        to: LINKS.reporting.ca,
      },
      {
        label: "Points",
        to: LINKS.reporting.operationnelle,
      },
      {
        label: "Véhicule",
        to: LINKS.reporting.vehicule,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
