import React, { forwardRef, useRef, useState } from "react";

// material-ui
// import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

// assets
import { CloseSquareFilled } from "@ant-design/icons";

// ==============================|| HEADER SORT ||============================== //

interface HeaderSortProps {
  column: any;
  sort?: boolean;
}

export const HeaderSort = ({ column, sort }: HeaderSortProps) => {
  // const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ display: "inline-flex" }}
    >
      <Box>{column.render("Header")}</Box>
      {/* {!column.disableSortBy && (
        <Stack
          sx={{ color: "secondary.light" }}
          {...(sort && {
            ...column.getHeaderProps(column.getSortByToggleProps()),
          })}
        >
          <CaretUpOutlined
            style={{
              fontSize: "0.625rem",
              color:
                column.isSorted && !column.isSortedDesc
                  ? theme.palette.text.secondary
                  : "inherit",
            }}
          />
          <CaretDownOutlined
            style={{
              fontSize: "0.625rem",
              marginTop: -2,
              color: column.isSortedDesc
                ? theme.palette.text.secondary
                : "inherit",
            }}
          />
        </Stack>
      )} */}
    </Stack>
  );
};

// ==============================|| TABLE PAGINATION ||============================== //

interface TablePaginationProps {
  setPageSize: (value: number) => void;
  gotoPage: (value: number) => void;
  pageIndex: number;
  pageSize: number;
  totalPage: number;
  total: number;
  totalInDatabase: number;
}

export const TablePagination = ({
  totalPage,
  pageSize,
  pageIndex,
  total,
  totalInDatabase,
  setPageSize,
  gotoPage,
}: TablePaginationProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    gotoPage(value);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "auto" }}
    >
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Résultats par page</Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                // @ts-ignore
                value={pageSize}
                onChange={handleChange}
                size="small"
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        sx={{ mt: { xs: 2, sm: 0 } }}
        style={{ color: "#777", fontStyle: "italic", fontSize: "12px" }}
      >
        <b>{total}</b> items filtrés
        {totalInDatabase ? (
          <>
            / <b>{totalInDatabase}</b> items en BD
          </>
        ) : (
          ""
        )}
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          // @ts-ignore
          count={totalPage}
          // @ts-ignore
          page={pageIndex + 1}
          onChange={handleChangePagination}
          color="primary"
          variant="combined"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

interface TablePaginationSimpleProps {
  gotoPage: (value: number) => void;
  setPageSize: (value: number) => void;
  pageIndex: number;
  pageSize: number;
  rows: any[];
}

export const TablePaginationSimple = ({
  gotoPage,
  rows,
  setPageSize,
  pageSize,
  pageIndex,
}: TablePaginationSimpleProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    gotoPage(value - 1);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "auto" }}
    >
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="secondary">
              Résultats par page
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                // @ts-ignore
                value={pageSize}
                onChange={handleChange}
                size="small"
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          // @ts-ignore
          count={Math.ceil(rows.length / pageSize)}
          // @ts-ignore
          page={pageIndex + 1}
          onChange={handleChangePagination}
          color="primary"
          variant="combined"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

// ==============================|| SELECTION - PREVIEW ||============================== //

export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: { indeterminate: any }, ref: any) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    return (
      <Checkbox indeterminate={indeterminate} ref={resolvedRef} {...rest} />
    );
  }
);

export const TableRowSelection = ({ selected }: { selected: number }) => (
  <>
    {selected > 0 && (
      <Chip
        size="small"
        // @ts-ignore
        label={`${selected} row(s) selected`}
        color="secondary"
        variant="light"
        sx={{
          position: "absolute",
          right: -1,
          top: -1,
          borderRadius: "0 4px 0 4px",
        }}
      />
    )}
  </>
);

// ==============================|| DRAG & DROP - DRAG PREVIEW ||============================== //

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export const HidingSelect = ({
  hiddenColumns,
  setHiddenColumns,
  allColumns,
}: any) => {
  const handleChange = (event: SelectChangeEvent<typeof hiddenColumns>) => {
    const {
      target: { value },
    } = event;

    setHiddenColumns(typeof value === "string" ? value.split(",") : value!);
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        id="column-hiding"
        multiple
        displayEmpty
        value={hiddenColumns}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-column-hiding"
            placeholder="select column"
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <Typography variant="subtitle1">all columns visible</Typography>
            );
          }

          if (selected.length > 0 && selected.length === allColumns.length) {
            return (
              <Typography variant="subtitle1">all columns hidden</Typography>
            );
          }

          return (
            <Typography variant="subtitle1">
              {selected.length} column(s) hidden
            </Typography>
          );
        }}
        MenuProps={MenuProps}
        size="small"
      >
        {allColumns.map((column: any, index: number) => (
          <MenuItem key={column.id} value={column.id}>
            <Checkbox
              checked={hiddenColumns!.indexOf(column.id) > -1}
              color="error"
              checkedIcon={
                <Box
                  className="icon"
                  sx={{
                    width: 16,
                    height: 16,
                    border: "1px solid",
                    borderColor: "inherit",
                    borderRadius: 0.25,
                    position: "relative",
                  }}
                >
                  <CloseSquareFilled
                    className="filled"
                    style={{ position: "absolute" }}
                  />
                </Box>
              }
            />
            <ListItemText
              primary={
                typeof column.Header === "string"
                  ? column.Header
                  : column?.title
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// ==============================|| COLUMN SORTING - SELECT ||============================== //

export const SortingSelect = ({ sortBy, setSortBy, allColumns }: any) => {
  const [sort, setSort] = useState(sortBy);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setSort(value);
    setSortBy([{ id: value, desc: false }]);
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        id="column-hiding"
        displayEmpty
        value={sort}
        onChange={handleChange}
        input={
          <OutlinedInput id="select-column-hiding" placeholder="Sort by" />
        }
        renderValue={(selected) => {
          const selectedColumn = allColumns.filter(
            (column: any) => column.id === selected
          )[0];
          if (!selected) {
            return <Typography variant="subtitle1">Sort By</Typography>;
          }

          return (
            <Typography variant="subtitle2">
              Sort by (
              {typeof selectedColumn.Header === "string"
                ? selectedColumn.Header
                : selectedColumn?.title}
              )
            </Typography>
          );
        }}
        size="small"
      >
        {allColumns
          .filter((column: any) => column.canSort)
          .map((column: any) => (
            <MenuItem key={column.id} value={column.id}>
              <ListItemText
                primary={
                  typeof column.Header === "string"
                    ? column.Header
                    : column?.title
                }
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
