import { FormattedMessage } from "react-intl";

import { UserModule } from "types";
import { filterSousMenu } from "menu-items";
import { LINKS } from "utils/constant";
import { ReportProblemRounded, SecurityRounded } from "@mui/icons-material";

const sousmenus = [
  {
    id: "vehicules",
    title: <FormattedMessage id="menu.transport.vehicule.title" />,
    type: "item",
    url: LINKS.vehicule.list,
  },
  {
    id: "incidents",
    title: "Incidents",
    type: "item",
    url: "/incidents",
    icon: ReportProblemRounded,
  },
];

export const buildSecuriteMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-securites",
      type: "group",
      children: [
        {
          id: "menu.clientele.title",
          title: "Sécurité",
          type: "collapse",
          icon: SecurityRounded,
          children: data,
        },
      ],
    };
  }
  return null;
};
