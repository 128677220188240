import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReferentielResource } from "types/referentiel.type";

type ReferentielState = {
  referentiels: ReferentielResource[];
};

const initialState: ReferentielState = {
  referentiels: [],
};

const referentielSlice = createSlice({
  name: "referentiel",
  initialState,
  reducers: {
    setReferentiels(state, action: PayloadAction<ReferentielResource[]>) {
      state.referentiels = action.payload;
    },
  },
});

export default referentielSlice.reducer;

export const { setReferentiels } = referentielSlice.actions;

const getReferentielReducer = (state: any): ReferentielState =>
  state.referentiel;

export const selectReferentiels = createSelector(
  getReferentielReducer,
  ({ referentiels }) => referentiels
);
