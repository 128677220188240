// material-ui
import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.85rem",
          padding: 12,
          border: `1px solid`,
          borderColor: theme.palette.divider,
        },
        sizeSmall: {
          padding: 8,
        },
        head: {
          fontSize: "0.8rem",
          fontWeight: 700,
          textTransform: "uppercase",
        },
        footer: {
          fontSize: "0.8rem",
          textTransform: "uppercase",
        },
      },
    },
  };
}
