import { Button, Paper } from "@mui/material";
import { Grid } from "@mui/material";
import { PageTitle } from "pages/common";

import {
  AutoCompleteAsyncHookForm,
  DateInput,
  MonthInput,
  YearInput,
} from "components";
import { FileDownload, Paid } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { ButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectChiffreAffaireFilter, setChiffreAffaireFilter } from "reducers";
import {
  ChiffreAffaireParAgence,
  ChiffreAffaireParClient,
  ChiffreAffaireParSecteur,
} from "./ca";
import { Fragment } from "react";

export const TYPE_DATA = [
  {
    label: "CA par client",
    value: "ca_client",
  },
  {
    label: "CA par secteur",
    value: "ca_secteur",
  },
  {
    label: "CA par agence",
    value: "ca_agence",
  },
];

export const PERIODES = [
  {
    label: "Par jour",
    value: "daily",
  },
  {
    label: "Par semaine",
    value: "hebdo",
  },
  {
    label: "Par mois",
    value: "mensuel",
  },
];

const ChiffreAffaire = () => {
  const filter = useSelector(selectChiffreAffaireFilter);
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: filter,
  });

  const setFilter = (name: string, value: any) => {
    dispatch(setChiffreAffaireFilter({ ...filter, [name]: value }));
  };

  const renderContent = () => {
    if (filter?.type?.value === "ca_secteur") {
      return <ChiffreAffaireParSecteur />;
    }
    if (filter?.type?.value === "ca_agence") {
      return <ChiffreAffaireParAgence />;
    }
    return <ChiffreAffaireParClient />;
  };

  return (
    <>
      <PageTitle
        title="Chiffre d'affaire"
        subtitle="Analyse du chiffre d'affaire"
        TitleIcon={Paid}
        sx={{ padding: "1rem 0 0rem 0" }}
        right={
          <Button variant="contained" startIcon={<FileDownload />}>
            Exporter
          </Button>
        }
      />

      <FormProvider {...methods}>
        <Grid container spacing={2} marginTop={1}>
          <Grid item md={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={3}>
                <ButtonGroup
                  size="large"
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: "auto" }}
                  disableElevation
                >
                  <Button variant="contained">Liste</Button>
                  <Button>Graphique</Button>
                </ButtonGroup>
              </Grid>
              <Grid item md={9}>
                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <AutoCompleteAsyncHookForm
                      label="Type de données"
                      name="type"
                      defaultOptions={TYPE_DATA}
                      afterSelected={setFilter}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <AutoCompleteAsyncHookForm
                      label="Période"
                      name="periode"
                      defaultOptions={PERIODES}
                      afterSelected={setFilter}
                    />
                  </Grid>
                  {filter?.periode?.value === "mensuel" ? (
                    <Fragment key="mensuel">
                      <Grid item xs={12} md={3}>
                        <MonthInput
                          name="mois"
                          label="Mois *"
                          afterSelected={setFilter}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <YearInput
                          name="annee"
                          label="Année *"
                          afterSelected={setFilter}
                        />
                      </Grid>
                    </Fragment>
                  ) : filter?.periode?.value === "hebdo" ? (
                    <Fragment key="periode">
                      <Grid item xs={12} md={3}>
                        <DateInput
                          name="dateDebut"
                          label="Date début"
                          afterSelected={setFilter}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <DateInput
                          name="dateFin"
                          label="Date fin *"
                          afterSelected={setFilter}
                        />
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment key="daily">
                      <Grid item xs={12} md={3}>
                        <DateInput
                          name="date"
                          label="Date *"
                          afterSelected={setFilter}
                        />
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Paper elevation={0} sx={{ marginTop: 2 }}>
              {renderContent()}
            </Paper>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default ChiffreAffaire;
