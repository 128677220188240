import { FC } from "react";
import { Grid } from "@mui/material";
import { ViewItem2 as ViewItem } from "pages/common";
import { MontantFormatText } from "components";
import { desserteHelper } from "utils/helpers";

type MontantIntraMurosProps = {
  montantForfaitaire?: number;
  montantJrFerie?: number;
  montantMensuel?: number;
  pourcentageColis?: number;
  modeFacture: number;
};

export const MontantIntraMuros: FC<MontantIntraMurosProps> = ({
  montantForfaitaire,
  montantJrFerie,
  montantMensuel,
  pourcentageColis,
  modeFacture,
}) => {
  const renderMontant = () => {
    const modeFactureAsString = modeFacture.toString();
    if (desserteHelper.isFacturationParDesserte(modeFactureAsString)) {
      return (
        <>
          <Grid item xs={12} md={3}>
            <ViewItem label="FCFA / Desserte">
              {montantForfaitaire && (
                <MontantFormatText value={montantForfaitaire} />
              )}
            </ViewItem>
          </Grid>

          <Grid item xs={12} md={3}>
            <ViewItem label="FCFA / Dimanche et jour férié">
              {montantJrFerie && <MontantFormatText value={montantJrFerie} />}
            </ViewItem>
          </Grid>
        </>
      );
    }

    if (desserteHelper.isFacturationMensuel(modeFactureAsString)) {
      return (
        <Grid item xs={12} md={3}>
          <ViewItem label="FCFA / Mensuel">
            {montantMensuel && <MontantFormatText value={montantMensuel} />}
          </ViewItem>
        </Grid>
      );
    }

    if (desserteHelper.isFacturationPourcentage(modeFactureAsString)) {
      return (
        <Grid item xs={12} md={3}>
          <ViewItem label="% Colis">
            {pourcentageColis && <MontantFormatText value={pourcentageColis} />}
          </ViewItem>
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid item xs={12} md={3}>
        <ViewItem label="Mode facture">
          {desserteHelper.getLibelleModeFacture(modeFacture)}
        </ViewItem>
      </Grid>
      {renderMontant()}
    </>
  );
};

type MontantExtraMurosProps = {
  kmVoieBitumee?: number;
  kmVoieNonBitumee?: number;
  montantKmVb?: number;
  montantKmVnb?: number;
};

export const MontantExtraMuros: FC<MontantExtraMurosProps> = ({
  montantKmVb,
  montantKmVnb,
  kmVoieBitumee,
  kmVoieNonBitumee,
}) => {
  return (
    <>
      <Grid item xs={12} md={3}>
        <ViewItem label="Bitume FCFA / Km">
          {montantKmVb && <MontantFormatText value={montantKmVb} />}
        </ViewItem>
      </Grid>
      <Grid item xs={12} md={3}>
        <ViewItem label="Kilomètre voie bitumée">{kmVoieBitumee}</ViewItem>
      </Grid>
      <Grid item xs={12} md={3}>
        <ViewItem label="Piste FCFA / Km">
          {montantKmVnb && <MontantFormatText value={montantKmVnb} />}
        </ViewItem>
      </Grid>

      <Grid item xs={12} md={3}>
        <ViewItem label="Kilomètre piste">{kmVoieNonBitumee}</ViewItem>
      </Grid>

      <Grid item xs={12} md={3}>
        <ViewItem
          label="Montant desserte"
          contentSx={{ fontWeight: 700 }}
          labelSx={{ fontWeight: 400 }}
        >
          <MontantFormatText
            value={desserteHelper.computeMontant({
              kmVoieBitumee,
              montantKmVb,
              kmVoieNonBitumee,
              montantKmVnb,
            })}
          />
        </ViewItem>
      </Grid>
    </>
  );
};
