import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterProps, ListPaginated } from "types/list.type";
import { PersonnelItem } from "types/personnel.type";

type PersonnelState = {
  personnels: ListPaginated<PersonnelItem>;
  filter: FilterProps;
};

const initialState: PersonnelState = {
  personnels: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  filter: {
    page: 1,
    perpage: 10,
  },
};

const personnelSlice = createSlice({
  name: "personnel",
  initialState,
  reducers: {
    setPersonnels(state, action: PayloadAction<ListPaginated<PersonnelItem>>) {
      state.personnels = action.payload;
    },
    setPersonnelFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.filter = payload;
    },
  },
});

export default personnelSlice.reducer;

export const { setPersonnels, setPersonnelFilter } = personnelSlice.actions;

const getPersonnelReducer = (state: any): PersonnelState => state.personnel;

export const selectPersonnels = createSelector(
  getPersonnelReducer,
  ({ personnels }) => personnels
);

export const selectPersonnelFilter = createSelector(
  getPersonnelReducer,
  ({ filter }) => filter
);
