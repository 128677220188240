import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { fonctionService, serviceService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

export const SelectServiceAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await serviceService.findAll();
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};

export const SelectFonctionAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await fonctionService.findAll();

    return {
      data: data.map(({ libelle, id, service }) => ({
        ...buildSelectItem(libelle, id),
        service,
      })),
      hasMore: false,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={(optionProps, _option) => {
        const option = _option as any;
        return (
          <Stack>
            <Typography variant="subtitle1">{option.label}</Typography>
            <Typography color="secondary" variant="caption">
              {option.service?.libelle}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};
