import { useMemo, useState } from "react";

// material-ui
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// assets
import {
  Ballot,
  Help,
  ManageAccounts,
  PowerSettingsNew,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { useUser } from "hooks";
import { hasRoleAdmin } from "utils/user.helpers";

interface Props {
  handleLogout: () => void;
  closeMenu: () => void;
}

const ProfileTab = ({ handleLogout, closeMenu }: Props) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { user } = useUser();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    value: string
  ) => {
    setSelected(value);
    closeMenu();
  };
  const MENU = useMemo(() => {
    const MENU = [
      {
        primary: "Mon compte",
        code: "compte",
        secondary: "Consulter votre compte",
        Icon: <Ballot />,
        link: LINKS.users.view(user.id),
      },
    ];

    if (hasRoleAdmin(user)) {
      MENU.push(
        {
          primary: "Utilisateurs",
          code: "utilisateurs",
          secondary: "Gestion des utilisateurs",
          Icon: <ManageAccounts />,
          link: LINKS.users.list,
        },
        {
          primary: "Rôles",
          code: "roles",
          secondary: "Gestion des rôles et périmètres",
          Icon: <Settings />,
          link: LINKS.roles.list,
        }
      );
    }

    MENU.push({
      primary: "Support",
      code: "support",
      secondary: "Aide et utilisations",
      Icon: <Help />,
      link: "",
    });
    return MENU;
  }, [user]);

  return (
    <List
      component="nav"
      sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 35 } }}
    >
      {MENU.map((menu) => (
        <ListItemButton
          divider
          key={menu.code}
          selected={selected === menu.code}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            handleListItemClick(event, menu.code);
            menu.link && navigate(menu.link);
          }}
        >
          <ListItemIcon>{menu.Icon}</ListItemIcon>
          <ListItemText primary={menu.primary} secondary={menu.secondary} />
        </ListItemButton>
      ))}

      <ListItemButton
        color="error"
        // selected={selected === 4}
        onClick={handleLogout}
        sx={{
          bgcolor: (t) => t.palette.error.main,
          color: "#fff",
          "&:hover": {
            bgcolor: (t) => t.palette.error.light,
          },
        }}
      >
        <ListItemIcon>
          <PowerSettingsNew sx={{ color: "#fff" }} />
        </ListItemIcon>
        <ListItemText primary="Se deconnecter" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
