import { PictureAsPdf } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  ButtonProps,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FC, useState } from "react";

export const LoadingButton: FC<
  {
    loading?: boolean;
  } & ButtonProps
> = ({ loading = false, children, ...props }) => {
  return (
    <Button
      {...props}
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size={15} />
        ) : (
          props.startIcon
        )
      }
      disabled={loading || props.disabled}
    >
      {children}
    </Button>
  );
};

export const ExportIconButton: FC<
  {
    download: () => Promise<any>;
  } & ButtonProps
> = ({ children, download, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false);

  if (loading) {
    return (
      <CircularProgress color="primary" size={27} sx={{ marginLeft: 0.5 }} />
    );
  }

  return (
    <Tooltip title="Télécharger la facture en PDF">
      <IconButton
        sx={{ marginLeft: 0.5 }}
        color="primary"
        size="small"
        onClick={() => {
          setLoading(true);
          download()
            .then(() => {})
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        <PictureAsPdf />
      </IconButton>
    </Tooltip>
  );
};
