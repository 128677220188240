import { FC } from "react";
import { magasinService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

export const SelectMagasinAsycForm: FC<SelectAsyncProps> = ({
  queryParams,
  ...props
}) => {
  const fetchData = async () => {
    const { data } = await magasinService.findAll(queryParams);
    return {
      data: data.map(({ libelle, id }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};
