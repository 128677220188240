// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const LieuNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "Pays",
        to: LINKS.lieux.pays,
      },
      {
        label: "Villes",
        to: LINKS.lieux.villes,
      },
      {
        label: "Situation Geographiques",
        to: LINKS.lieux.situationGeographiques,
      },
      {
        label: "Zones",
        to: LINKS.lieux.zones,
      },
      {
        label: "Centres régionaux",
        to: LINKS.lieux.centresRegionaux,
      },
      {
        label: "Centres",
        to: LINKS.lieux.centres,
      },
      {
        label: "Devises",
        to: LINKS.lieux.devises,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
