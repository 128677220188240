import { Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { CellPopover, TableShowMoreColumn } from "components/table";
import { FC } from "react";
import { PersonnelResource } from "types/personnel.type";
import { EquipageResource } from "types/transport.types";

type PersonnelAvatarProps = {
  personnel: PersonnelResource;
  withMatricule?: boolean;
  subtitle?: string;
};

export const PersonnelAvatar: FC<PersonnelAvatarProps> = ({
  personnel,
  withMatricule = true,
  subtitle,
}) => (
  <Stack flexDirection="row" alignItems="center">
    <Avatar resource="personne" color="info" src={personnel.photo} />
    <Stack marginLeft={1}>
      <Typography fontWeight={600} color="primary">
        {personnel.firstname}&nbsp;
        {personnel.lastname}
      </Typography>
      {withMatricule && (
        <Typography fontSize="0.8rem" color="textSecondary">
          {personnel.matricule}
        </Typography>
      )}
      {subtitle && (
        <Typography fontSize="0.8rem" color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </Stack>
  </Stack>
);

export const EquipagePopover: FC<{ equipage: EquipageResource }> = ({
  equipage,
}) => {
  return (
    <Stack display="flex" flexDirection="row">
      <Stack>
        <Typography component="span" color="textPrimary" variant="subtitle1">
          {equipage.chefDeBord.firstname} {equipage.chefDeBord.lastname}
        </Typography>
        <Typography component="span" color="textSecondary" variant="caption">
          Chef de bord
        </Typography>
      </Stack>
      <CellPopover name="commande">
        <TableShowMoreColumn
          data={[
            {
              title: "Chef de bord",
              value: <PersonnelAvatar personnel={equipage.chefDeBord} />,
            },
            {
              title: "Agent de garde",
              value: <PersonnelAvatar personnel={equipage.agentGarde} />,
            },
            {
              title: "Chauffeur",
              value: <PersonnelAvatar personnel={equipage.chauffeur} />,
            },
          ]}
        />
      </CellPopover>
    </Stack>
  );
};
