import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResource } from "types";
import { FilterProps, ListPaginated } from "types/list.type";

import { INIT_FILTRE, INIT_LIST } from "utils/constant";

type UserState = {
  users: ListPaginated<UserResource>;
  userFilter: FilterProps;
};

const initialState: UserState = {
  users: { ...INIT_LIST },
  userFilter: { ...INIT_FILTRE },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<ListPaginated<UserResource>>) {
      state.users = action.payload;
    },
    setUserFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.userFilter = payload;
    },
    setUserFilterByName(
      state,
      { payload }: PayloadAction<{ name: string; value: any }>
    ) {
      state.userFilter[payload.name] = payload.value;
    },
  },
});

export default userSlice.reducer;

export const { setUsers, setUserFilter, setUserFilterByName } =
  userSlice.actions;

const getUserReducer = (state: any): UserState => state.user;

export const selectUsers = createSelector(getUserReducer, ({ users }) => users);

export const selectUserFilter = createSelector(
  getUserReducer,
  ({ userFilter }) => userFilter
);
