import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { articleService } from "services";

import { ArticleResource } from "types/stock.type";
import { useModal } from "hooks";
import { addOrUpdateElement, numberHelper, selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { ArticleModal } from "./ArticleModal";
import { useQueryClient } from "@tanstack/react-query";

const ListArticle = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ArticleResource[]>([]);
  const modal = useModal();
  const queryClient = useQueryClient();

  useEffect(() => {
    setLoading(true);

    articleService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    const body = {
      ...data,
      familleArticleId: selectHelper.getValue(data.familleArticle),
    };
    body.prixRevient = 0; //numberHelper.trim(body.prixRevient);
    body.prixVente = 0; //numberHelper.trim(body.prixVente);
    body.seuilAlerte = numberHelper.trim(body.seuilAlerte) || 0;
    if (defaultValues?.id) {
      return articleService.update(defaultValues.id, body);
    }
    return articleService.create(body);
  };

  const onEdit = (original: ArticleResource) => {
    setDefaultValues({
      id: original.id,
      designation: original.designation,
      code: original.code,
      familleArticle: selectHelper.buildFamilleArticle(original.familleArticle),
      // prixRevient: original.prixRevient,
      // prixVente: original.prixVente,
      seuilAlerte: original.seuilAlerte,
      stock: original.stock,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: ArticleResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    queryClient.invalidateQueries(["articles"]);
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Code - Désignation",
        accessor: "designation",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">
            {original.code} - {original.designation}
          </Typography>
        ),
      },
      {
        Header: "Famille",
        accessor: "famille",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.familleArticle?.designation || "-"}</Typography>
        ),
      },
      // {
      //   Header: "Prix de revient",
      //   accessor: "prixRevient",
      //   Cell: ({ row: { original } }: any) => (
      //     <Typography>{original.prixRevient}</Typography>
      //   ),
      // },
      // {
      //   Header: "Prix de vente",
      //   accessor: "prixVente",
      //   Cell: ({ row: { original } }: any) => (
      //     <Typography>{original.prixVente}</Typography>
      //   ),
      // },
      {
        Header: "Seuil d'alerte",
        accessor: "seuilAlerte",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.seuilAlerte}</Typography>
        ),
      },
      {
        Header: "Stock",
        accessor: "stock",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.stock}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la fonction">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Articles"
        subtitle="Consulter et gérer les articles"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer un article
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <ArticleModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListArticle;
