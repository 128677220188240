import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
  Typography,
  Box,
  Theme,
  Fade,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";

type CollapseProps = {
  title: string;
  subtitle?: string;
  actions?: ReactNode;
  children: ReactNode;
  sx?: SxProps<Theme>;
  headerBottom?: number;
  defaultValue?: boolean;
  loading?: boolean;
  leftIcon?: ReactNode;
  toggleContent?: (showContent: boolean) => void;
};

export const Collapse: FC<CollapseProps> = ({
  title,
  subtitle,
  actions,
  sx,
  loading = false,
  defaultValue = true,
  headerBottom,
  children,
  leftIcon,
  toggleContent,
}) => {
  const [showContent, setShowContent] = useState<boolean>(
    defaultValue ? true : false
  );

  const toggle = () => {
    toggleContent && toggleContent(!showContent);
    setShowContent((o) => !o);
  };

  return (
    <Box component="div" sx={sx}>
      <Stack
        sx={{
          marginBottom: showContent ? headerBottom || 3 : 0,
        }}
        flexDirection="row"
        alignItems="center"
      >
        <Stack flexDirection="row">
          {leftIcon && (
            <Box sx={{ paddingTop: 0.5, marginRight: 1 }}>{leftIcon}</Box>
          )}
          <Stack>
            <Typography
              sx={{
                fontWeight: "500 !important",
                "&:hover": {
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
              onClick={toggle}
              component="span"
              variant="h4"
              marginBottom={0}
            >
              {title}
            </Typography>
            {showContent ? (
              <Typography marginBottom={0} color="textSecondary">
                {subtitle}
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  color: (t) => t.palette.secondary.main,
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: (t) => t.palette.primary.main,
                  },
                }}
                onClick={toggle}
                // fontWeight={600}
                component="span"
              >
                <i>Voir le contenu</i>
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack flexDirection="row" marginLeft="auto" alignItems="center">
          {loading && (
            <CircularProgress
              sx={{ fontSize: "1rem", marginRight: 1 }}
              size={25}
            />
          )}
          {actions}
          <IconButton
            sx={{
              backgroundColor: (theme: any) => theme.palette.grey[100],
            }}
            color="primary"
            onClick={toggle}
          >
            {showContent ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Stack>
      </Stack>
      {showContent && (
        <Fade in>
          <Box>{children}</Box>
        </Fade>
      )}
    </Box>
  );
};
