import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// material-ui
import { Theme } from "@mui/material/styles";
import { useMediaQuery, Box, Dialog } from "@mui/material";

// third-party
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  // EventDropArg,
} from "@fullcalendar/react";
// import interactionPlugin, {
//   EventResizeDoneArg,
// } from "@fullcalendar/interaction";
// import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";

// project import
// import { RootStateProps } from "types/root";
// import {
//   getEvents,
//   selectEvent,
//   selectRange,
//   toggleModal,
//   updateCalendarView,
//   updateEvent,
// } from "store/reducers/calendar";

// types
// import { PlusOutlined } from "@ant-design/icons";
import { CalendarStyled } from "./CalendarStyled";
import CalendarToolbar from "./CalendarToolbar";

// const selectedEventHandler = (state: RootStateProps) => {
//   const { events, selectedEventId } = state.calendar;
//   if (selectedEventId) {
//     return events.find((event: any) => event.id === selectedEventId);
//   }
//   return null;
// };

// ==============================|| CALENDAR - MAIN ||============================== //

export const Calendar = () => {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const dispatch = useDispatch();
  // const calendar = useSelector((state: RootStateProps) => state.calendar);
  // const { calendarView, events, isModalOpen, selectedRange } = calendar;
  // const selectedEvent = useSelector(selectedEventHandler);

  useEffect(() => {
    // dispatch(getEvents());
  }, [dispatch]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = matchDownSM ? "listWeek" : "timeGridWeek";
      calendarApi.changeView(newView);
      // dispatch(updateCalendarView(newView));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownSM]);

  const calendarRef = useRef<FullCalendar>(null);

  const [date, setDate] = useState(new Date());

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      // const calendarApi = calendarEl.getApi();
      // calendarApi.changeView(newView);
      // dispatch(updateCalendarView(newView));
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  // calendar events
  const handleRangeSelect = (arg: DateSelectArg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    // dispatch(selectRange(arg.start, arg.end));
  };

  const handleEventSelect = (arg: EventClickArg) => {
    // dispatch(selectEvent(arg.event.id));
  };

  // const handleEventUpdate = async ({
  //   event,
  // }: EventResizeDoneArg | EventDropArg) => {
  //   try {
  //     dispatch(
  //       updateEvent(event.id, {
  //         allDay: event.allDay,
  //         start: event.start,
  //         end: event.end,
  //       })
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleModal = () => {
    // dispatch(toggleModal());
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CalendarStyled>
        <CalendarToolbar
          date={date}
          view={"timeGridWeek"}
          onClickNext={handleDateNext}
          onClickPrev={handleDatePrev}
          onClickToday={handleDateToday}
          onChangeView={handleViewChange}
        />

        {
          //@ts-ignore
        }

        <FullCalendar
          weekends
          editable
          droppable
          selectable
          locale="fr"
          // events={events}
          events={[
            { title: "event 1", date: "2022-08-16 10:11:26" },
            { title: "event 2", date: "2022-08-16 12:11:26" },
          ]}
          ref={calendarRef}
          rerenderDelay={10}
          initialDate={date}
          initialView={"timeGridWeek"}
          dayMaxEventRows={3}
          eventDisplay="block"
          headerToolbar={false}
          allDayMaintainDuration
          eventResizableFromStart
          select={handleRangeSelect}
          // eventDrop={handleEventUpdate}
          eventClick={handleEventSelect}
          // eventResize={handleEventUpdate}
          // height={matchDownSM ? "auto" : 720}
          height="auto"
          plugins={[dayGridPlugin, timeGridPlugin, timelinePlugin]}
        />
      </CalendarStyled>

      {/* Dialog renders its body even if not open */}
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleModal}
        open={false}
        sx={{ "& .MuiDialog-paper": { p: 0 } }}
      >
        {/* {isModalOpen && (
          <AddEventForm
            event={selectedEvent}
            range={selectedRange}
            onCancel={handleModal}
          />
        )} */}
      </Dialog>
      <p style={{ height: "30px" }}></p>
    </Box>
  );
};
