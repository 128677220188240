import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IncidentResource } from "types/incidents.types";
import { FilterProps, ListPaginated } from "types/list.type";

type IncidentState = {
  incidents: ListPaginated<IncidentResource>;
  filter: FilterProps;
};

const initialState: IncidentState = {
  incidents: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  filter: {
    page: 1,
    perpage: 10,
  },
};

const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    setIncidents(
      state,
      action: PayloadAction<ListPaginated<IncidentResource>>
    ) {
      state.incidents = action.payload;
    },
    setIncidentFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.filter = payload;
    },
  },
});

export default incidentSlice.reducer;

export const { setIncidents, setIncidentFilter } = incidentSlice.actions;

const getIncidentReducer = (state: any): IncidentState => state.incident;

export const selectIncidents = createSelector(
  getIncidentReducer,
  ({ incidents }) => incidents
);

export const selectIncidentFilter = createSelector(
  getIncidentReducer,
  ({ filter }) => filter
);
