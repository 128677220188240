import { Chip } from "@mui/material";
import { FC } from "react";
import { ETAT_COLOR } from "utils/helpers";

type EtatProps = {
  label: string;
  value?: string;
};
export const Etat: FC<EtatProps> = ({ label, value }) => {
  return (
    <Chip
      label={label}
      color={value ? ETAT_COLOR[value] || "default" : "default"}
      size="small"
      // variant="light"
    />
  );
};
