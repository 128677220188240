import { useEffect, useMemo, useState } from "react";

import {
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { magasinService } from "services";

import { MagasinResource } from "types/stock.type";
import { useModal } from "hooks";
import { addOrUpdateElement, selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { MagasinModal } from "./MagasinModal";

const ListMagasin = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<MagasinResource[]>([]);
  const modal = useModal();

  useEffect(() => {
    setLoading(true);

    magasinService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    const body = { ...data, centreId: selectHelper.getValue(data.centreId) };
    if (defaultValues?.id) {
      return magasinService.update(defaultValues.id, body);
    }
    return magasinService.create(body);
  };

  const onEdit = (original: MagasinResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      centreId: selectHelper.buildCentre(original.centre),
      principal: original.principal,
      corbeille: original.corbeille,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: MagasinResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <>
            <Typography variant="subtitle1">{original.libelle}</Typography>
            {original.principal === "1" &&
              <Chip label="Principal" sx={{ marginRight: 1 }} color="warning" />
            }
            {original.corbeille === "1" &&
              <Chip label="Corbeille" sx={{ marginRight: 1 }} color="default" />
            }
          </>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Centre",
        accessor: "centre",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.centre?.libelle || "-"}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la fonction">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Magasin"
        subtitle="Consulter et gérer les magasins"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer un magasin
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <MagasinModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListMagasin;
