import { useEffect, useMemo, useState } from "react";

import { Button, IconButton, Typography, useTheme } from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { secteurActiviteService } from "services";

import { useModal } from "hooks";
import { SecteurActiviteModal } from "./SecteurActiviteModal";
import { SecteurActiviteResource } from "types/referentiel.type";
import { addOrUpdateElement } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";

const ListSecteurActivite = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<SecteurActiviteResource[]>([]);
  const [selected, setSelected] = useState<
    SecteurActiviteResource | undefined
  >();
  const modal = useModal();

  useEffect(() => {
    setLoading(true);

    secteurActiviteService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    if (selected) {
      return secteurActiviteService.update(selected.id, data);
    }
    return secteurActiviteService.create({ ...data });
  };

  const closeModal = () => {
    setSelected(undefined);
    modal.close();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <IconButton
            color="error"
            size="large"
            onClick={() => {
              setSelected(original);
              modal.show();
            }}
          >
            <EditTwoTone twoToneColor={theme.palette.error.main} />
          </IconButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Secteur d'activités"
        subtitle="Consulter et gérer les secteurs d'activités"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer un secteur d'activité
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <SecteurActiviteModal
          onBack={closeModal}
          defaultValues={{ libelle: selected?.libelle, code: selected?.code }}
          onFinished={(element) => {
            const index = data.findIndex(({ id }) => id === element.id);
            setData((d) => addOrUpdateElement(d, element, index));
            closeModal();
          }}
          onSave={save}
        />
      )}
    </>
  );
};

export default ListSecteurActivite;
