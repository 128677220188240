import { PersonnelItem, PersonnelResource } from "types/personnel.type";
import { CrudService } from "./crudService";

const base = "personnels";

const crudService = new CrudService<PersonnelItem, PersonnelResource>(base);

export const personnelService = {
  ...crudService,
};
