import { FC } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { desserteHelper } from "utils/helpers";
import { TransportDeFondModeFacture } from "./ModeFactureTdfForm";
import { RegimeTransportDeFond } from "./RegimeTdfForm";
import {
  MontantTransportDeFondExtraMuros,
  MontantTransportDeFondIntraMuros,
} from "./MontantTdfForm";

type PrestationMontantFormProps = {
  hasTdf: boolean;
  loading: boolean;
};
export const PrestationMontantForm: FC<PrestationMontantFormProps> = ({
  hasTdf,
  loading,
}) => {
  const { watch } = useFormContext();

  const isIntraMuros = desserteHelper.isIntraMuros(
    watch("transport.intramuros")
  );

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <RegimeTransportDeFond hasTdf={hasTdf} />
      </Grid>

      {isIntraMuros && <TransportDeFondModeFacture hasTdf={hasTdf} />}

      <Grid item xs={12}>
        <Grid container spacing={1.3} columns={15}>
          {isIntraMuros ? (
            <MontantTransportDeFondIntraMuros hasTdf={hasTdf} />
          ) : (
            <MontantTransportDeFondExtraMuros hasTdf={hasTdf} />
          )}
          {loading && (
            <Grid item xs={3} sm={3}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
