import { Grid } from "@mui/material";

import { FC } from "react";
import { ContactResource } from "types/client.type";
import {
  ViewIcons,
  ViewItem2 as ViewItem,
  ViewList,
  ViewListItem,
  ViewListItemEmpty,
} from "pages/common";

import { Collapse } from "components";
import { uuid } from "utils/helpers";

type ContactsProps = {
  contacts?: ContactResource[];
};

export const ViewContacts: FC<ContactsProps> = ({ contacts }) => {
  return (
    <Collapse
      title="Contacts"
      leftIcon={ViewIcons.contact}
      subtitle="Contacts des representants"
    >
      {contacts?.length ? (
        contacts.map((contact) => (
          <ViewList key={uuid()} sx={{ marginBottom: 2 }}>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="Nom contact">{contact.fullname}</ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Fonction contact">{contact.fonction}</ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Téléphone">{contact.telephone}</ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Email">{contact.email}</ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        ))
      ) : (
        <ViewListItemEmpty />
      )}
    </Collapse>
  );
};
