import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

export const useSituationGeographiqueDeps = () => {
  const { watch, setValue } = useFormContext();
  const centreId = watch("centre")?.value;
  const villeId = watch("ville")?.value;
  const situationGeographiqueId = watch("situationGeographique")?.value;
  // const zoneId = watch("zone")?.value;

  const hasCentre = useMemo(() => {
    return !!centreId;
  }, [centreId]);

  const ville = useMemo(
    () => ({
      resetDependencies: [centreId],
      queryParams: { centreId },
    }),
    [centreId]
  );

  const situationGeographique = useMemo(
    () => ({
      resetDependencies: [villeId, centreId],
      queryParams: { villeId, centreId },
    }),
    [villeId, centreId]
  );

  const zone = useMemo(
    () => ({
      resetDependencies: [situationGeographiqueId, villeId, centreId],
      queryParams: { situationGeographiqueId, villeId, centreId },
    }),
    [situationGeographiqueId, villeId, centreId]
  );

  const resetVilleDeps = () => {
    setValue("situationGeographique", null);
    setValue("zone", null);
  };

  const resetSituationGeographiqueDeps = () => {
    setValue("zone", null);
  };

  return {
    ville,
    situationGeographique,
    zone,
    resetVilleDeps,
    resetSituationGeographiqueDeps,
    hasCentre,
  };
};
