import { useEffect, useMemo, useState } from "react";

import {
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { prestationService } from "services";

import { PrestationModal } from "./PrestationModal";
import { useModal } from "hooks";
import { addOrUpdateElement, selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { PrestationResource } from "types/facturation.types";

const ListPrestation = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<PrestationResource[]>([]);
  const modal = useModal();

  useEffect(() => {
    setLoading(true);

    prestationService
      .findAll()
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const save = (data: any) => {
    if (defaultValues?.id) {
      return prestationService.update(defaultValues.id, {
        ...data,
        categorieId: selectHelper.getValue(data.categorieId),
        typeVehiculeId: selectHelper.getValue(data.typeVehiculeId),
      });
    }
    return prestationService.create({
      ...data,
      categorieId: selectHelper.getValue(data.categorieId),
      typeVehiculeId: selectHelper.getValue(data.typeVehiculeId),
    });
  };

  const onEdit = (original: PrestationResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      categorieId: selectHelper.buildReferentiel(original.categorie),
      typeVehiculeId: selectHelper.build(
        original.typeVehicule?.code,
        original.typeVehicule?.id
      ),
      montant: original.montant || 0,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: PrestationResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Type de véhicule",
        accessor: "typeVehiculeId",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original?.typeVehicule?.code || "-"}</Typography>
        ),
      },
      {
        Header: "Categorie",
        accessor: "categorie",
        Cell: ({ row: { original } }: any) => (
          <Chip label={original?.categorie?.name || "-"} />
        ),
      },
      {
        Header: "Montant",
        accessor: "montant",
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier la prestation">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Prestations"
        subtitle="Consulter et gérer les prestations"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer une prestation
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <PrestationModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListPrestation;
