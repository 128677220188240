import { FC } from "react";
import { prestationService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import { useQuery } from "@tanstack/react-query";

export const SelectPrestationAsyncForm: FC<SelectAsyncProps> = ({
  label,
  name,
  ...props
}) => {
  const result = useQuery({
    queryKey: ["prestations"],
    queryFn: () =>
      prestationService.findAll().then(({ data }) =>
        data.map((item) => {
          return {
            label: item.libelle,
            value: item.id,
          };
        })
      ),
    staleTime: 1000 * 60 * 5,
  });

  return (
    <AutoCompleteAsyncHookForm
      label={label}
      name={name}
      defaultOptions={result.data}
      {...props}
    />
  );
};
