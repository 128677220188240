import {
  DesserteResource,
  EquipageResource,
  TourneeResource,
} from "types/transport.types";
import { CrudService } from "./crudService";
import { requestGet, requestPost, requestPut } from "./request";

const base = "tournees";

const crudService = new CrudService<TourneeResource, TourneeResource>(base);

export const tourneeService = {
  ...crudService,
  duplicate: (tourneeId: string, body: any) => {
    return requestPost<{ data: TourneeResource }>(
      `${base}/${tourneeId}/duplicate`,
      body
    );
  },
  demarrer: (tourneeId: string) => {
    return requestPut<{ data: TourneeResource }>(
      `${base}/${tourneeId}/demarrer`
    );
  },
  terminer: (tourneeId: string) => {
    return requestPut<{ data: TourneeResource }>(
      `${base}/${tourneeId}/terminer`
    );
  },
  approuver: (tourneeId: string) => {
    return requestPut<{ data: TourneeResource }>(
      `${base}/${tourneeId}/approuver`
    );
  },
  findDessertes: (tourneeId: string) => {
    return requestGet<{ data: DesserteResource[] }>(
      `${base}/${tourneeId}/dessertes`
    ).then(({ data }) => data);
  },
  findEquipages: (tourneeId: string) => {
    return requestGet<{ data: EquipageResource[] }>(
      `${base}/${tourneeId}/equipages`
    ).then(({ data }) => data);
  },
  ajouterDessertes: (tourneeId: string, body: any) => {
    return requestPost<{ data: DesserteResource[] }>(
      `${base}/${tourneeId}/ajouter-dessertes`,
      body
    );
  },
  ajouterEquipageJonction: (tourneeId: string, body: any) => {
    return requestPost<{ data: EquipageResource }>(
      `${base}/${tourneeId}/equipages`,
      body
    );
  },
  changerDesserteDestination: (
    tourneeId: string,
    equipageId: string,
    desserteId: string,
    body: any
  ) => {
    return requestPut(
      `${base}/${tourneeId}/equipages/${equipageId}/dessertes/${desserteId}/changer-destination`,
      body
    );
  },

  replanifierDesserte: (desserteId: string, tourneeId: string, body: any) => {
    return requestPut(
      `${base}/${tourneeId}/dessertes/${desserteId}/a-replanifier`,
      body
    );
  },
};
