import {
  Skeleton,
  Stack,
  SxProps,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, Fragment } from "react";
import {
  useGlobalFilter,
  useTable,
  usePagination,
  Column,
  useFilters,
  Row,
} from "react-table";
import {
  HeaderSort,
  TablePaginationSimple,
} from "components/third-party/ReactTable";
import MainCard from "components/MainCard";
import { GlobalFilter } from "utils/react-table";
import { Collapse } from "components/common";
import { Subject } from "@mui/icons-material";

// @ts-ignore
function fuzzyTextFilterFn(rows, id, filterValue) {
  // @ts-ignore
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export const renderFilterTypes = () => ({
  fuzzyText: fuzzyTextFilterFn,
  text: (rows: Row[], id: string, filterValue: string) => {
    rows.filter((row: Row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
});

type TableProps = {
  columns: Column[];
  data: any[];
  getHeaderProps?: (column: any) => void;
  loading?: boolean;
  withGlobalFilter?: boolean;
  tableHeadSx?: SxProps;
  withPagination?: boolean;
  title?: string;
};

export const TableSimple: FC<TableProps> = ({
  columns,
  data,
  withGlobalFilter = true,
  loading = false,
  withPagination = false,
  tableHeadSx,
  title,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { globalFilter, pageIndex, pageSize },
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      filterTypes: renderFilterTypes,
      // @ts-ignore
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const renderContent = () => {
    if (loading) {
      return (
        <TableBody>
          {new Array(10).fill(1).map((_, index) => (
            <TableRow key={index}>
              {new Array(columns.length).fill(1).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    }

    if (!data.length) {
      return (
        <TableBody {...getTableBodyProps()}>
          <TableRow
            sx={{
              "&:hover": { bgcolor: "transparent !important" },
            }}
          >
            <TableCell
              sx={{ p: 2, py: 3, textAlign: "center" }}
              colSpan={columns.length}
            >
              Aucun résultat
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody {...getTableBodyProps()}>
        {page.map((row: any, i: number) => {
          prepareRow(row);

          return (
            <Fragment key={i}>
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <TableCell
                    {...cell.getCellProps([
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            </Fragment>
          );
        })}
        {withPagination && (
          <TableRow
            sx={{
              "&:hover": { bgcolor: "transparent !important" },
              bgcolor: (t: any) => t.palette.grey[100],
            }}
          >
            <TableCell sx={{ p: 2, py: 3 }} colSpan={columns.length}>
              <TablePaginationSimple
                gotoPage={gotoPage}
                rows={rows}
                setPageSize={setPageSize}
                pageSize={pageSize}
                pageIndex={pageIndex}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <>
      {withGlobalFilter && (
        <Stack sx={{ marginBottom: 2 }}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="small"
          />
        </Stack>
      )}

      <MainCard>
        <Collapse
          title={title || "Liste"}
          subtitle={data?.length ? `${data.length} résultat(s)` : ""}
          loading={loading}
          leftIcon={<Subject />}
        >
          <MainCard elevation={0} content={false}>
            <MuiTable {...getTableProps()}>
              <TableHead sx={{ ...(tableHeadSx || {}) }}>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <TableCell
                        {...column.getHeaderProps([
                          { className: column.className },
                        ])}
                      >
                        <HeaderSort column={column} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              {renderContent()}
            </MuiTable>
          </MainCard>
        </Collapse>
      </MainCard>
    </>
  );
};
