import { Stack, Typography } from "@mui/material";
import { useRestrictions, useUser } from "hooks";
import { FC, useMemo } from "react";
import { centreService } from "services";
import { RequestParam } from "types";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import {
  AutoCompleteAsync,
  AutoCompleteAsyncHookForm,
  FetchDataProps,
} from "../AutoComplete";
import { useQuery } from "@tanstack/react-query";

interface CentreItem extends SelectItem {
  centreRegional: string;
}

type LoadDataProps = {
  centreRegional?: SelectItem;
};

const useLoadData = ({ centreRegional, ...rest }: LoadDataProps) => {
  const { paysId } = useUser();

  const fetchData = async ({ page }: FetchDataProps) => {
    const value = centreRegional?.value;
    const params: RequestParam = { page, paysId, ...rest };

    if (centreRegional?.value) {
      params.centreRegionalId = value;
    }
    const { data, total, totalPage } = await centreService.findAll(params);
    return {
      data: data.map(({ id, libelle, centreRegional }) => ({
        label: libelle,
        value: id,
        centreRegional: centreRegional.libelle,
        paysId,
      })),
      hasMore: total < totalPage,
    };
  };

  const resetDependencies = useMemo(() => {
    return centreRegional ? [centreRegional] : [];
  }, [centreRegional]);

  return { fetchData, resetDependencies };
};

const renderOption = (_: any, _option: any) => {
  const option = _option as CentreItem;
  return (
    <Stack>
      <Typography variant="subtitle1">{option.label}</Typography>
      <Typography color="secondary" variant="caption">
        {option.centreRegional}
      </Typography>
    </Stack>
  );
};

export const CentreAsyncSelectForm: FC<
  SelectAsyncProps & { withRestrictions?: boolean }
> = ({ withRestrictions, queryParams, ...props }) => {
  const { getCentreRequestRestrictions } = useRestrictions();

  const result = useQuery({
    queryKey: ["centres"],
    queryFn: async () => {
      const { data } = await centreService.findAll();
      return data.map((item) => ({
        label: item.libelle,
        value: item.id,
        centreRegional: item.centreRegional.libelle,
        centreRegionalId: item.centreRegional.id,
      }));
    },
    staleTime: 60 * 60 * 1_000, // Expire au bout d'une heure
  });

  const data = useMemo(() => {
    const queryParams = getCentreRequestRestrictions();
    if (queryParams?.centreId) {
      return result.data?.filter((e) => e.value === queryParams.centreId);
    }
    if (queryParams?.centreRegionalId) {
      return result.data?.filter(
        (e) => e.centreRegionalId === queryParams.centreRegionalId
      );
    }
    return result.data;
  }, [getCentreRequestRestrictions, result.data]);

  return (
    <AutoCompleteAsyncHookForm
      {...props}
      defaultOptions={data || []}
      renderOption={renderOption}
    />
  );
};

export const SelectCentreAsync: FC<SelectAsyncProps> = (props) => {
  const loadData = useLoadData({});

  return (
    <AutoCompleteAsync
      variant="standard"
      {...props}
      {...loadData}
      renderOption={renderOption}
    />
  );
};
