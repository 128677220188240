import { Table, TableRow } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import { useEffect, useState } from "react";
import { reportingService } from "services";

import { ThousandText } from "components";
import { useSelector } from "react-redux";
import { selectChiffreAffaireFilter } from "reducers";
import { dateFormater } from "utils/date";
import { ReportingCell } from "../common";

export const ChiffreAffaireParSecteur = () => {
  const [data, setData] = useState<any[]>([]);
  const filter = useSelector(selectChiffreAffaireFilter);

  useEffect(() => {
    const { type, periode } = filter;

    if ([type, periode].some((e) => !e)) {
      return;
    }
    const params: Record<string, any> = {
      type: type.value,
      periode: periode.value,
    };

    if (periode.value === "mensuel") {
      params.mois = dateFormater.getMonth(filter.mois);
      params.annee = dateFormater.getYear(filter.annee);
      reportingService.findCaMensuelBySecteurs(params).then(setData);
    }
  }, [filter]);

  return (
    <>
      <Table
        sx={{
          th: {
            fontWeight: 600,
            textAlign: "center",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <ReportingCell>Secteurs</ReportingCell>
            <ReportingCell>Montant</ReportingCell>
            <ReportingCell>TOTAL</ReportingCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRow key={item.id}>
                <ReportingCell>{item.libelle}</ReportingCell>
                <ReportingCell>
                  <ThousandText value={item.montant} />
                </ReportingCell>
                <ReportingCell>
                  <ThousandText value={item.cumul} />
                </ReportingCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
