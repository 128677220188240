import { Grid } from "@mui/material";
import {
  ViewItem2 as ViewItem,
  ViewList,
  ViewListItem,
  ViewListItemEmpty,
} from "pages/common";
import { FC } from "react";
import { SignatureResource } from "types/transport.types";

type ListSignatureProps = {
  signatures?: SignatureResource[];
};

export const ListSignature: FC<ListSignatureProps> = ({ signatures }) => {
  return (
    <>
      {signatures?.length ? (
        signatures.map((signature) => (
          <ViewList key={signature.id} sx={{ marginBottom: 2 }}>
            <ViewListItem>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ViewItem label="Signataire">
                      {signature.signataire}
                    </ViewItem>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ViewItem label="Objet">{signature.objet}</ViewItem>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ViewItem label="Date signature">{signature.date}</ViewItem>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Signature">
                  {signature.signature && (
                    <img
                      src={signature.signature}
                      style={{ width: 100, height: 100 }}
                      alt="Signature"
                    />
                  )}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        ))
      ) : (
        <ViewListItemEmpty />
      )}
    </>
  );
};
