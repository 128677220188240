import { RequestParam } from "types";
import { SiteItem, SiteResource } from "types/client.type";
import { SitePrestationResource } from "types/facturation.types";
import { requestParams } from "utils/helpers";
import { CrudService } from "./crudService";
import { requestBlob, requestGet } from "./request";

const base = "sites";

const crudService = new CrudService<SiteItem, SiteResource>(base);

export const siteService = {
  ...crudService,
  findPrestations: (siteId: string, params?: RequestParam) => {
    return requestGet<{ data: SitePrestationResource[] }>(
      `${base}/${siteId}/prestations${requestParams(params)}`
    ).then(({ data }) => data);
  },
  showQrCode: (siteId: string) => {
    return requestBlob(`${base}/${siteId}/qrcode`).then((response) => {
      return response.text();
    });
  },
};
