import { ChangeEvent, FC } from "react";

import { useTheme } from "@mui/material/styles";
import { Box, FormLabel, Stack, Typography } from "@mui/material";

import Avatar from "components/@extended/Avatar";

import { CameraOutlined } from "@ant-design/icons";
import { useFormContext } from "react-hook-form";

type ProfilMediaProps = {
  name: string;
};

export const ProfilMedia: FC<ProfilMediaProps> = ({ name }) => {
  const theme = useTheme();
  const { setValue, watch } = useFormContext();

  return (
    <>
      <FormLabel
        htmlFor={name}
        sx={{
          position: "relative",
          // borderRadius: "50%",
          overflow: "hidden",
          "&:hover .MuiBox-root": { opacity: 1 },
          cursor: "pointer",
          width: "100%",
          height: "200px",
        }}
      >
        <Avatar
          alt="Avatar 1"
          src={watch(name)}
          variant="square"
          sx={{ width: "100%", height: "100%", minWidth: "60px" }}
          color="secondary"
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, .75)"
                : "rgba(0,0,0,.65)",
            width: "100%",
            height: "100%",
            opacity: 0.1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            margin: 0,
          }}
        >
          <Stack spacing={0} alignItems="center">
            <CameraOutlined
              style={{
                color: theme.palette.secondary.lighter,
                fontSize: "2rem",
              }}
            />
            <Typography sx={{ color: "secondary.lighter" }}>
              Choisir une image
            </Typography>
          </Stack>
        </Box>
      </FormLabel>
      <input
        type="file"
        id={name}
        style={{ display: "none" }}
        accept="image/*"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            if (reader.result) {
              setValue(name, reader.result as string);
            }
          };
          reader.readAsDataURL(e.target.files?.[0] as any);
        }}
      />
    </>
  );
};
