import { People } from "@mui/icons-material";
import { UserModule } from "types";
import { filterSousMenu } from "menu-items";

const sousmenus = {
  id: "personnels",
  title: "Personnel",
  type: "item",
  url: "/personnels",
  icon: People,
};

export const buildPersonnelMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, [sousmenus]);

  if (data.length) {
    return {
      id: "group-personnels",
      type: "group",
      children: data,
    };
  }
  return null;
};
