// material-ui
import { Theme } from "@mui/material/styles";
import { ColorProps } from "types/extended";

// project import
import getShadow from "utils/getShadow";

// types
interface Props {
  variant: ColorProps;
  theme: Theme;
}

// ==============================|| OVERRIDES - INPUT BORDER & SHADOWS ||============================== //

function getColor({ variant, theme }: Props) {
  const shadows = getShadow(theme, `${variant}`);

  return {
    "&.Mui-focused": {
      boxShadow: shadows,
      // "& .MuiOutlinedInput-notchedOutline": {
      //   border: `1px solid ${light}`,
      // },
    },
  };
}

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontWeight: 500,
        },

        notchedOutline: {
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.grey[200]
              : theme.palette.grey[300],
        },
        root: {
          ...getColor({ variant: "primary", theme }),
          "&.Mui-error": {
            ...getColor({ variant: "error", theme }),
          },
          backgroundColor: "#fff",
          // boxShadow:
          //   "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
        },
        inputSizeSmall: {
          padding: "12.5px 8px 15px 12.5px",
          fontSize: "0.9rem",
        },
        inputMultiline: {
          // padding: 0,
        },
        colorSecondary: getColor({ variant: "secondary", theme }),
        colorError: getColor({ variant: "error", theme }),
        colorWarning: getColor({ variant: "warning", theme }),
        colorInfo: getColor({ variant: "info", theme }),
        colorSuccess: getColor({ variant: "success", theme }),
      },
    },
  };
}
