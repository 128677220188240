import { CentreResource } from "types/centre.type";
import { CrudService } from "./crudService";

const base = "centres";

const crudService = new CrudService<CentreResource, CentreResource>(base);

export const centreService = {
  ...crudService,
};
