import { Calendar } from "components/calendar";
import { useUser } from "hooks";
import { Navigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { isClient } from "utils/user.helpers";

export const HomePage = () => {
  const { user } = useUser();

  if (isClient(user)) {
    return <Navigate to={LINKS.clients.list} />;
  }
  return (
    <>
      <Calendar />
    </>
  );
};
