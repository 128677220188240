import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CentreRegionalResource, CentreResource } from "types/centre.type";

type CentreState = {
  centresRegionaux: CentreRegionalResource[];
  centres: CentreResource[];
};

const initialState: CentreState = {
  centresRegionaux: [],
  centres: [],
};

const centreSlice = createSlice({
  name: "centre",
  initialState,
  reducers: {
    setCentresRegionaux(
      state,
      action: PayloadAction<CentreRegionalResource[]>
    ) {
      state.centresRegionaux = action.payload;
    },
    setCentres(state, action: PayloadAction<CentreResource[]>) {
      state.centres = action.payload;
    },
  },
});

export default centreSlice.reducer;

export const { setCentresRegionaux, setCentres } = centreSlice.actions;

const getCentreReducer = (state: any): CentreState => state.centre;

export const selectCentres = createSelector(
  getCentreReducer,
  ({ centres }) => centres
);

export const selectCentresRegionaux = createSelector(
  getCentreReducer,
  ({ centresRegionaux }) => centresRegionaux
);
