import { Grid } from "@mui/material";
import { TextInput, ThousandFormat } from "components";
import { SelectFamilleArticleAsyncForm } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";

import * as yup from "yup";

const schema = yup.object({
  code: yup.string().required().nullable(),
  designation: yup.string().required().nullable(),
  familleArticle: yup.object().required().nullable(),
  // prixVente: yup.string().required().nullable(),
  // prixRevient: yup.string().required().nullable(),
});

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={
        props.isEditMode ? "Mise à jour d'articles" : "Ajouter un d'article"
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput label="Code *" name="code" disabled={props.isEditMode} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput label="Nom *" name="designation" />
        </Grid>
        <Grid item xs={4}>
          <SelectFamilleArticleAsyncForm
            label="Famille d'article *"
            name="familleArticle"
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextInput
            label="Prix de revient *"
            name="prixRevient"
            InputProps={{
              inputComponent: ThousandFormat as any,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Prix de vente *"
            name="prixVente"
            InputProps={{
              inputComponent: ThousandFormat as any,
            }}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextInput
            label="Seuil d'alerte"
            name="seuilAlerte"
            InputProps={{
              inputComponent: ThousandFormat as any,
            }}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const ArticleModal = withForm(Form, schema);
