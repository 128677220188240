import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "store";

const BurvalApp = () => (
  <BrowserRouter basename="/">
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<BurvalApp />);
