import { Alert, AlertTitle, Snackbar, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { closeAlert, selectNotification } from "reducers";

export const Notification = () => {
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      sx={{
        borderRadius: 5,
      }}
      open={notification.show}
      autoHideDuration={7000}
      onClose={close}
    >
      <Alert
        elevation={3}
        onClose={close}
        severity={notification.severity}
        variant="filled"
        sx={{ padding: 2, borderRadius: 2 }}
      >
        <AlertTitle sx={{ fontWeight: 600, fontSize: "0.9rem" }}>
          {notification.title}
        </AlertTitle>
        <Typography fontSize={"0.9rem"}>{notification.message}</Typography>
      </Alert>
    </Snackbar>
  );
};
