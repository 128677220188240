import {
  Box,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { FC, ReactNode } from "react";

export const ViewList: FC<{ children: ReactNode; sx?: SxProps }> = (props) => {
  return (
    <List
      component={Paper}
      sx={{
        backgroundColor: "#fff",
        ...(props.sx || {}),
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 1%), 0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 1px 3px 0px rgb(0 0 0 / 8%);",
      }}
    >
      {props.children}
    </List>
  );
};

interface ViewListItemProps {
  children: ReactNode;
  sx?: SxProps;
}

export const ViewListItem: FC<ViewListItemProps> = ({ sx, children }) => {
  return (
    <ListItem sx={{ py: 1.5, ...(sx || {}) }}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </ListItem>
  );
};

type ViewItemProps = {
  label?: string;
  children?: ReactNode;
  media?: string;
  withMedia?: boolean;
  contentSx?: SxProps;
  labelSx?: SxProps;
};

export const ViewItem: FC<ViewItemProps> = ({
  label,
  media,
  withMedia = false,
  children,
}) => {
  return (
    <Stack flexDirection="row">
      {withMedia && <Avatar src={media} size="lg" sx={{ marginRight: 1.5 }} />}
      <Stack spacing={media ? 0 : 0.25}>
        {label && (
          <Typography
            // color="secondary"
            sx={{
              fontSize: "0.8rem",
              fontWeight: 600,
            }}
          >
            {label}
          </Typography>
        )}
        <Box component="div" sx={{ fontSize: "1rem", fontWeight: 400 }}>
          {children === undefined || children === null || children === ""
            ? "-"
            : children}
        </Box>
      </Stack>
    </Stack>
  );
};

export const ViewListItemEmpty: FC<{ text?: string }> = ({ text }) => (
  <Typography
    component="i"
    fontWeight={600}
    fontSize={"0.8rem"}
    sx={{ color: (t) => t.palette.grey[400] }}
  >
    {text || "non renseigné"}
  </Typography>
);

export const ViewItem2: FC<ViewItemProps> = ({
  label,
  media,
  withMedia = false,
  children,
  contentSx = {},
  labelSx = {},
}) => {
  return (
    <Stack flexDirection="row">
      {withMedia && <Avatar src={media} size="lg" sx={{ marginRight: 1.5 }} />}
      <Stack sx={contentSx}>
        <Typography
          sx={{
            fontSize: "0.85rem",
            fontWeight: 600,
            marginBottom: 0.25,
            ...(labelSx || {}),
          }}
          // color="#324253"
        >
          {label}
        </Typography>
        <Box
          component="div"
          sx={{
            fontWeight: 400,
            color: "#000",
            fontSize: "0.95rem",
          }}
        >
          {children === undefined || children === null || children === "" ? (
            <ViewListItemEmpty />
          ) : (
            children
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
