import { UserResource } from "types";

export const isClient = (user?: UserResource) => {
  return user?.userableType === "client";
};

export const isPersonnel = (user?: UserResource) => {
  return user?.userableType === "personnel";
};

export const isPersonnelOrAdmin = (user?: UserResource) => {
  return isPersonnel(user) || (!!user && hasRoleAdmin(user));
};

export const hasRole = (user: UserResource, role: string) => {
  const roles = (user.roles || []).map(({ code }) => code);
  return roles.includes(role);
};

export const hasPerimetre = (user: UserResource, perimetre: string) => {
  const perimetres = (user.roles || []).map(({ perimetre }) => perimetre.id);
  return perimetres.includes(perimetre);
};

export const hasRoleAdmin = (user: UserResource) => {
  return hasRole(user, "admin");
};

export const hasRoleClient = (user: UserResource) => {
  return hasRole(user, "client");
};

export const hasPerimetreCentre = (user: UserResource) => {
  return hasPerimetre(user, "centre");
};

export const hasPerimetreChefCentreRegional = (user: UserResource) => {
  return hasPerimetre(user, "centre_regional");
};

export const hasPerimetreToutCentre = (user: UserResource) => {
  return hasPerimetre(user, "tout");
};
