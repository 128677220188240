import { FC } from "react";
import { Box, List } from "@mui/material";
import { useSelector } from "react-redux";
import { selectNotifications } from "reducers";
import { NotificationItem } from "./NotificationItem";
import { useParams } from "react-router-dom";

export const ListNotification: FC = () => {
  const notifications = useSelector(selectNotifications);
  const params = useParams();
  return (
    <Box sx={{ pt: 0 }}>
      <List
        component="nav"
        sx={{
          py: 0,
          px: 0.25,
          "&:last-child": {
            borderBottom: "0 !important",
          },
        }}
      >
        {(notifications.data || []).map((notification) => (
          <NotificationItem
            notification={notification}
            key={notification.id}
            onSelected={() => {}}
            selected={params.id === notification.id}
          />
        ))}
      </List>
    </Box>
  );
};
