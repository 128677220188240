import { Grid, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

export const ProgrammeWrapper: FC<{ children: ReactNode; title: string }> = ({
  title,
  children,
}) => {
  return (
    <Grid item xs={12}>
      <Typography fontWeight={600} sx={{ marginBottom: 2 }}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};
