import { useEffect, useMemo, useState } from "react";

import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

// assets
import { Collapse, Table, TableFilter, TextInput, YearInput } from "components";
import { PageTitle } from "pages/common";
import { ferieService } from "services";

import { FerieResource } from "types/centre.type";
import { useModal, useUser } from "hooks";
import { addOrUpdateElement } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { FerieModal } from "./FerieModal";
import { DuplicateModal } from "./DuplicateModal";
import { dateFormater, dateParser } from "utils/date";
import { selectFerieFilter, setFerieFilter } from "reducers";
import MainCard from "components/MainCard";
import { Subject } from "@mui/icons-material";
import { RequestParam } from "types";

const ListFerie = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [data, setData] = useState<FerieResource[]>([]);
  const modal = useModal();
  const duplicateModal = useModal();
  const { paysId } = useUser();

  const filter = useSelector(selectFerieFilter);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    const _filter: RequestParam = {
      search: filter?.search?.trim() ? filter.search : null,
      annee: filter?.annee ? filter?.annee : new Date().getFullYear(),
      page: filter?.page,
      perpage: 999,
      paysId: paysId,
    };

    ferieService
      .findAll(_filter)
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [paysId, filter]);

  const setFilter = (value: Record<string, any>) => {
    const data = { ...value };
    if (value.annee) {
      data.annee = dateFormater.getYear(value.annee);
    }
    dispatch(setFerieFilter(data));
  };

  const save = (data: any) => {
    data.annee = dateFormater.getYear(data.date);
    data.date = dateFormater.toBackPattern(data.date, "yyyy-MM-dd");
    data.paysId = paysId;
    if (defaultValues?.id) {
      return ferieService.update(defaultValues.id, {
        ...data,
      });
    }
    return ferieService.create({
      ...data,
    });
  };

  const onEdit = (original: FerieResource) => {
    setDefaultValues({
      id: original.id,
      date: dateParser.toDate(original.date),
      libelle: original.libelle,
    });
    modal.show();
  };

  const onDuplicate = () => {
    duplicateModal.show();
  };

  const saveDuplication = (data: any) => {
    data.fromCountry = paysId;
    data.fromYear = dateFormater.getYear(data.fromYear);
    data.toYear = dateFormater.getYear(data.toYear);
    return ferieService.duplicate({
      ...data,
    });
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: FerieResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.date || "-"}</Typography>
        ),
      },
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Annee",
        accessor: "annee",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.annee || "-"}</Typography>
        ),
      },
      {
        Header: "Pays",
        accessor: "pays",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.pays.libelle || "-"}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier le jour férié">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Jours fériés"
        subtitle="Consulter et gérer les jours fériés"
        sx={{ padding: "1.5rem 0" }}
        right={
          <>
            <Button variant="contained" onClick={modal.show}>
              Créer un jour férié
            </Button>
            <Button
              variant="outlined"
              onClick={() => onDuplicate()}
              style={{ marginLeft: "10px" }}
            >
              Dupliquer les jours fériés
            </Button>
          </>
        }
      />
      <TableFilter
        setFilter={setFilter}
        defaultValues={filter}
        subtitle="Rechercher et filtrer les jours fériés"
      >
        <Grid item md={9}>
          <TextInput
            name="search"
            placeholder="Recherche de jour férié"
            size="small"
            disabled={true}
          />
        </Grid>
        <Grid item md={3}>
          <YearInput label="Année" name="annee" />
        </Grid>
      </TableFilter>

      <MainCard>
        <Collapse
          title="Liste des jours fériés"
          loading={loading}
          leftIcon={<Subject />}
        >
          <Table
            columns={columns}
            data={data}
            loading={!data.length && loading}
            filter={filter}
            setFilter={setFilter}
          />
        </Collapse>
      </MainCard>

      {modal.open && (
        <FerieModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
      {duplicateModal.open && (
        <DuplicateModal
          onBack={duplicateModal.close}
          onFinished={() => {
            duplicateModal.close();
          }}
          defaultValues={{
            fromYear: new Date(),
            toYear: new Date(new Date().getFullYear() + 1, 0, 1),
          }}
          onSave={saveDuplication}
        />
      )}
    </>
  );
};

export default ListFerie;
