import { FC } from "react";
import { articleService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";
import { useQuery } from "@tanstack/react-query";
import { SelectItem } from "types/form.type";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";

interface ArticleItem extends SelectItem {
  prixVente?: number;
  prixRevient?: number;
  code: string;
  stock: number;
}

export const SelectArticleAsyncForm: FC<
  SelectAsyncProps & {
    showStock?: boolean;
  }
> = ({ showStock, ...props }) => {
  const result = useQuery(["articles"], () =>
    articleService.findAll().then(({ data }) =>
      data.map((item) => {
        return {
          label: item.designation,
          value: item.id,
          ...item,
        } as ArticleItem;
      })
    )
  );

  return (
    <AutoCompleteAsyncHookForm
      defaultOptions={result.data}
      {...props}
      renderOption={(props, _option) => {
        const option = _option as ArticleItem;
        return (
          <>
            <Stack spacing={0.25}>
              <Typography variant="subtitle1">{option.label}</Typography>
              <Typography color="secondary" variant="caption">
                {option.code}
              </Typography>
            </Stack>
          </>
        );
      }}
    />
  );
};
