import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, ReactNode } from "react";

type TableShowMoreProps = {
  data: Array<{
    title: string;
    value: ReactNode;
  }>;
};

export const StripedRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableShowMoreColumn: FC<TableShowMoreProps> = ({ data }) => {
  return (
    <Table>
      <TableBody>
        {data.map(({ title, value }, i) => (
          <StripedRow key={i}>
            <TableCell>
              <Typography variant="subtitle1">{title}</Typography>
            </TableCell>
            <TableCell>{value}</TableCell>
          </StripedRow>
        ))}
      </TableBody>
    </Table>
  );
};
