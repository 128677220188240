import { FC } from "react";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";
import { Collapse, TextInput } from "components";
import { ViewIcons } from "pages/common";
import { Add, Delete } from "@mui/icons-material";

export const ContactForm: FC = () => {
  const { control } = useFormContext();
  const contactsArray = useFieldArray({ control, name: "contacts" });

  const addButton = (
    <Button
      disableElevation
      size="small"
      onClick={() => {
        contactsArray.append({});
      }}
      variant="contained"
      startIcon={<Add />}
    >
      Ajouter un contact
    </Button>
  );

  return (
    <>
      <Collapse
        title="Contact"
        subtitle="Renseigner contacts des representants"
        leftIcon={ViewIcons.contact}
      >
        {contactsArray.fields.map((field, i) => (
          <Box
            key={field.id}
            sx={{
              padding: 1,
              marginBottom: 1,
              bgcolor: (t) => t.palette.grey[50],
            }}
          >
            <Box sx={{ display: "flex", marginBottom: 1 }}>
              <Tooltip title="Supprimer le contact">
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  size="small"
                  color="error"
                  onClick={() => {
                    contactsArray.remove(i);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  label="Nom et prénom *"
                  name={`contacts.${i}.fullname`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput label="Fonction" name={`contacts.${i}.fonction`} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput label="Téléphone" name={`contacts.${i}.telephone`} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  label="Adresse email *"
                  name={`contacts.${i}.email`}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        {addButton}
      </Collapse>
    </>
  );
};
