// material-ui
import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableHead(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          // backgroundColor: "rgb(243, 244, 246)",
          // backgroundColor: "rgb(251, 249, 219)",
          backgroundColor: "#eee",
          // borderTop: `1px solid ${theme.palette.divider}`,
          // borderBottom: `2px solid ${theme.palette.divider}`,
        },
      },
    },
  };
}
