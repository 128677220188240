import { ReferentielResource } from "types/referentiel.type";
import { CrudService } from "./crudService";

const base = "referentiels";

const crud = new CrudService<ReferentielResource, ReferentielResource>(base);

export const referentielService = {
  ...crud,
};
