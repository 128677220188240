import { Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { useUser } from "hooks";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { typeVehiculeService, vehiculeService } from "services";
import { RequestParam } from "types";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { TypeVehiculeResource } from "types/transport.types";
import { AutoCompleteAsyncHookForm, FetchDataProps } from "../AutoComplete";

interface VehiculeItem extends SelectItem {
  immatriculation: string;
  photo?: string;
  typeVehicule?: TypeVehiculeResource;
}

interface SelectAsyncVehiculeFormProps extends SelectAsyncProps {
  params?: RequestParam;
  centreField?: string;
}

export const SelectAsyncVehiculeForm: FC<SelectAsyncVehiculeFormProps> = ({
  label,
  name,
  params,
  centreField = "centre",
  ...rest
}) => {
  const { watch, getFieldState, setValue, clearErrors } = useFormContext();
  const centre = watch(centreField);
  const { paysId } = useUser();

  useEffect(() => {
    if (getFieldState(centreField).isTouched) {
      setValue(name, undefined);
      clearErrors(centreField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFieldState, setValue, name, clearErrors, centre]);

  const fetchData = async ({ page }: FetchDataProps) => {
    const _params: RequestParam = { page, paysId };

    if (centre?.value) {
      _params.centreId = centre?.value;
    }

    const { data, totalPage } = await vehiculeService.findAll({
      size: 100,
      ..._params,
      ...(params || {}),
    });
    return {
      data: data.map(
        ({ id, immatriculation, marque, photo, typeVehicule }) => ({
          label: marque,
          value: id,
          immatriculation,
          photo,
          typeVehicule,
        })
      ),
      hasMore: page < totalPage,
    };
  };

  const resetDependencies = useMemo(() => {
    return centre ? [centre] : [];
  }, [centre]);

  return (
    <AutoCompleteAsyncHookForm
      {...rest}
      label={label}
      name={name}
      fetchData={fetchData}
      resetDependencies={resetDependencies}
      renderOption={(props, _option) => {
        const option = _option as VehiculeItem;
        return (
          <>
            <Avatar
              src={option.photo}
              resource="vehicule"
              type="filled"
              sx={{ marginRight: 1, color: "textPrimary" }}
            />
            <Stack spacing={0.25}>
              <Typography variant="subtitle1">
                {option.label}{" "}
                {option.typeVehicule ? `(${option.typeVehicule.code})` : ""}
              </Typography>
              <Typography color="secondary" variant="caption">
                {option.immatriculation}
              </Typography>
            </Stack>
          </>
        );
      }}
    />
  );
};

export const SelectTypeVehiculeAsyncForm: FC<SelectAsyncProps> = ({
  label,
  name,
  ...rest
}) => {
  const fetchData = async () => {
    const { data } = await typeVehiculeService.findAll();
    return {
      data: data.map(({ id, code }) => ({
        label: code,
        value: id,
      })),
      hasMore: false,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      label={label}
      name={name}
      fetchData={fetchData}
      {...rest}
    />
  );
};
