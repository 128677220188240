import { FC } from "react";
import { secteurActiviteService } from "services";
import { SelectAsyncProps } from "types/select-async.type";
import { buildSelectItem } from "utils/helpers";
import { AutoCompleteAsyncHookForm } from "../AutoComplete";

export const SelectSecteurActiviteAsycForm: FC<SelectAsyncProps> = (props) => {
  const fetchData = async () => {
    const { data } = await secteurActiviteService.findAll();
    return {
      data: data.map(({ id, libelle }) => buildSelectItem(libelle, id)),
      hasMore: false,
    };
  };
  return <AutoCompleteAsyncHookForm {...props} fetchData={fetchData} />;
};
