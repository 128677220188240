import { useCallback, useState } from "react";

export const useModal = (defaultValue?: boolean) => {
  const [open, setOpen] = useState<boolean>(defaultValue || false);
  const show = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return { show, open, close, toggle };
};
