import {
  ParametrageResource,
  RequestParam,
  RoleResource,
  UserResource,
} from "types";
import {
  PaysResource,
  SituationGeographiqueResource,
  VilleResource,
  ZoneResource,
  FerieResource,
} from "types/centre.type";
import { ClientItem, ClientResource } from "types/client.type";
import {
  ClientPrestationResource,
  PrestationResource,
  TransportFactureResource,
  FactureItemResource,
} from "types/facturation.types";
import { IncidentResource } from "types/incidents.types";
import { NotificationResource } from "types/message.type";
import {
  ColisResource,
  DesserteResource,
  EquipageResource,
  RecuResource,
  TypeVehiculeResource,
} from "types/transport.types";
import { requestParams } from "utils/helpers";
import { CrudService } from "./crudService";
import { requestDelete, requestGet, requestPost, requestPut } from "./request";
import {
  FournisseurResource,
  MagasinResource,
  FamilleArticleResource,
  ArticleResource,
  ApprovisionnementResource,
  VenteResource,
  MagasinArticleResource,
  InventaireResource,
} from "types/stock.type";
import {
  BeneficiairePaiementResource,
  BeneficiaireResource,
  PaiementResource,
} from "types/paiement-sur-site.type";

export * from "./personnelService";
export * from "./referentielService";
export * from "./centreService";
export * from "./centreRegionalService";
export * from "./siteService";
export * from "./secteurActiviteService";
export * from "./vehiculeService";
export * from "./tourneeService";
export * from "./serviceService";
export * from "./fonctionService";
export * from "./authService";
export * from "./exportService";
export * from "./deviseService";

// Clients
const clients = new CrudService<ClientItem, ClientResource>("clients");
export const clientService = {
  ...clients,
  addPrestation: (clientId: string, body: any) => {
    return requestPost<{ data: PrestationResource[] }>(
      `${clients.getBase()}/${clientId}/prestations`,
      body
    ).then(({ data }) => data);
  },
  findPrestations: (clientId: string, params?: RequestParam) => {
    return requestGet<{ data: ClientPrestationResource[] }>(
      `${clients.getBase()}/${clientId}/prestations${requestParams(params)}`
    ).then(({ data }) => data);
  },
  showFacture: (clientId: string, params: RequestParam) => {
    return requestGet<{
      data: {
        factureItems: FactureItemResource[];
        annexeFactureItems: FactureItemResource[];
        montantHt: number;
        montantTva: number;
        montantTtc: number;
      };
    }>(`${clients.getBase()}/${clientId}/facture${requestParams(params)}`).then(
      ({ data }) => data
    );
  },
};

// client prestation
const clientPrestations = new CrudService<ClientItem, ClientResource>(
  "clientprestations"
);
export const clientPrestationService = {
  ...clientPrestations,
};

// prestations
const prestations = new CrudService<PrestationResource, PrestationResource>(
  "prestations"
);
export const prestationService = {
  ...prestations,
};

// Dessertes
const dessertes = new CrudService<DesserteResource, DesserteResource>(
  "dessertes"
);
export const desserteService = {
  ...dessertes,
  generate: (body: any) => {
    return requestPost(`${dessertes.getBase()}/generate-dailydesserte`, body);
  },
  transit: (desserteId: string, body: any) => {
    return requestPut<{ data: DesserteResource }>(
      `${dessertes.getBase()}/${desserteId}/transit`,
      body
    );
  },
  annuler: (desserteId: string) => {
    return requestPut<{ data: DesserteResource }>(
      `${dessertes.getBase()}/${desserteId}/annuler`
    );
  },
  signalerIncident: (desserteId: string, body: any) => {
    return requestPost<{ data: IncidentResource }>(
      `${dessertes.getBase()}/${desserteId}/signaler-incident`,
      body
    ).then(({ data }) => data);
  },
  livrer: (desserteId: string, body: any) => {
    return requestPut<{ data: DesserteResource }>(
      `${dessertes.getBase()}/${desserteId}/livrer`,
      body
    ).then(({ data }) => data);
  },
  prendreEnCharge: (desserteId: string, body: any) => {
    return requestPut<{ data: DesserteResource }>(
      `${dessertes.getBase()}/${desserteId}/prendre-en-charge`,
      body
    ).then(({ data }) => data);
  },
  aPlanifier: (params?: RequestParam) => {
    return requestGet<{ data: DesserteResource[] }>(
      `${dessertes.getBase()}/a-planifier${requestParams(params)}`
    );
  },
  findIncidents: (desserteId: string) => {
    return requestGet<{ data: IncidentResource[] }>(
      `${dessertes.getBase()}/${desserteId}/incidents`
    ).then(({ data }) => data);
  },
  findEquipages: (desserteId: string) => {
    return requestGet<{ data: EquipageResource[] }>(
      `${dessertes.getBase()}/${desserteId}/equipages`
    ).then(({ data }) => data);
  },
  deleteDessertesNonTraitees: (body: RequestParam) => {
    return requestDelete(dessertes.getBase(), body);
  },
};

// Pays

const pays = new CrudService<PaysResource, PaysResource>("pays");

export const paysService = {
  ...pays,
};

// Villes
const villes = new CrudService<VilleResource, VilleResource>("villes");
export const villeService = {
  ...villes,
};

// SituationGeographique
const situationGeographiques = new CrudService<
  SituationGeographiqueResource,
  SituationGeographiqueResource
>("situationgeographiques");
export const situationGeographiqueService = {
  ...situationGeographiques,
};

// Zone
const zones = new CrudService<ZoneResource, ZoneResource>("zones");
export const zoneService = {
  ...zones,
};

// Type vehicule
const typeVehicule = new CrudService<
  TypeVehiculeResource,
  TypeVehiculeResource
>("typeVehicules");

export const typeVehiculeService = {
  ...typeVehicule,
};

// users
const users = new CrudService<UserResource, UserResource>("users");

export const userService = {
  ...users,
  createUserFromPersonnel: (personnelId: string, body: any) => {
    return requestPost(`${users.getBase()}/personnels/${personnelId}`, body);
  },
};

// roles
const roles = new CrudService<RoleResource, RoleResource>("roles");

export const roleService = {
  ...roles,
};

// transportfactures
const transportfactures = new CrudService<any, any>("transportfactures");
export const transportfactureService = {
  ...transportfactures,
  generer: (body: any) => {
    return requestPost<{ data: TransportFactureResource[] }>(
      `${transportfactures.getBase()}/generer-factures`,
      body
    );
  },
  annuler: (tranportFactureId: string) => {
    return requestPut<{ data: TransportFactureResource }>(
      `${transportfactures.getBase()}/${tranportFactureId}/annuler`
    );
  },
};

//Jours feries
const feriesBase = "feries";
const feries = new CrudService<FerieResource, FerieResource>(feriesBase);
export const ferieService = {
  ...feries,
  duplicate: (body: any) => {
    return requestPost<{ data: FerieResource }>(
      `${feriesBase}/duplicate`,
      body
    );
  },
};

// notifications
const notifications = new CrudService<
  NotificationResource,
  NotificationResource
>("notifications");
export const notificationService = {
  ...notifications,
  isReaded: (id: string) => {
    return requestPut<{ data: NotificationResource }>(
      `${notifications.getBase()}/${id}/marquer-comme-lu`
    ).then(({ data }) => data);
  },
};

// notifications
const incidents = new CrudService<IncidentResource, IncidentResource>(
  "incidents"
);
export const incidentService = {
  ...incidents,
  addConversation: (incidentId: string, body: any) => {
    return requestPost<{ data: IncidentResource }>(
      `${incidents.getBase()}/${incidentId}/conversations`,
      body
    ).then(({ data }) => data);
  },
};

// equipages
const equipages = new CrudService<EquipageResource, EquipageResource>(
  "equipages"
);
export const equipageService = {
  ...equipages,
  changer: (equipageId: string, body: any) => {
    return requestPut(`${equipages.getBase()}/${equipageId}/changer`, body);
  },
  modifierTrajets: (equipageId: string, body: any) => {
    return requestPut(`${equipages.getBase()}/${equipageId}/trajets`, body);
  },
  passerLaMain: (equipageId: string) => {
    return requestPut(`${equipages.getBase()}/${equipageId}/passer-la-main`);
  },
  supprimer: (equipageId: string) => {
    return requestDelete(`${equipages.getBase()}/${equipageId}/jonction`);
  },
  prendreEnCharge: (equipageId: string, trajetId: string, body: any) => {
    return requestPost(
      `${equipages.getBase()}/${equipageId}/trajets/${trajetId}/prendre-en-charge`,
      body
    );
  },
  livrer: (equipageId: string, trajetId: string, body: any) => {
    return requestPost(
      `${equipages.getBase()}/${equipageId}/trajets/${trajetId}/livrer`,
      body
    );
  },
  annulerTrajet: (equipageId: string, trajetId: string) => {
    return requestPut(
      `${equipages.getBase()}/${equipageId}/trajets/${trajetId}/annuler`
    );
  },
  passerSite: (equipageId: string, trajetId: string) => {
    return requestPut(
      `${equipages.getBase()}/${equipageId}/trajets/${trajetId}/passer`
    );
  },
  supprimerTrajet: (equipageId: string, trajetId: string) => {
    return requestDelete(
      `${equipages.getBase()}/${equipageId}/trajets/${trajetId}/supprimer`
    );
  },
};

// colis
const colis = new CrudService<ColisResource, ColisResource>("colis");
export const colisService = { ...colis };

// parametrages
const parametrages = new CrudService<ParametrageResource, ParametrageResource>(
  "parametrages"
);
export const parametrageService = { ...parametrages };

const REPORT_TDF = "reportings/tdf";
// reporting
export const reportingService = {
  findPoints: (params: Record<string, any>, format?: string) => {
    return requestGet<{
      data: any[];
      cumul: any;
    }>(
      `${REPORT_TDF}/analyse-operationnelle${requestParams({
        ...params,
        format,
      })}`
    );
  },

  findChiffreAffaireByClients: (
    params: Record<string, any>,
    format?: string
  ) => {
    return requestGet<any>(
      `${REPORT_TDF}/ca/clients${requestParams({ ...params, format })}`
    );
  },

  findCaMensuelBySecteurs: (params: Record<string, any>, format?: string) => {
    return requestGet<any>(
      `${REPORT_TDF}/ca/secteurs${requestParams({ ...params, format })}`
    );
  },

  findCaMensuelByAgences: (params: Record<string, any>, format?: string) => {
    return requestGet<any>(
      `${REPORT_TDF}/ca/agences${requestParams({ ...params, format })}`
    );
  },
};

// Fournisseur
const fournisseur = new CrudService<FournisseurResource, FournisseurResource>(
  "fournisseurs"
);
export const fournisseurService = { ...fournisseur };

// Magasin
const magasin = new CrudService<MagasinResource, MagasinResource>("magasins");
export const magasinService = { ...magasin };

// FamilleArticle
const familleArticle = new CrudService<
  FamilleArticleResource,
  FamilleArticleResource
>("famille-articles");
export const familleArticleService = { ...familleArticle };

// Article
const article = new CrudService<ArticleResource, ArticleResource>("articles");
export const articleService = { ...article };

// MagasinArticle
const magasinArticle = new CrudService<
  MagasinArticleResource,
  MagasinArticleResource
>("magasin-articles");
export const magasinArticleService = {
  ...magasinArticle,

  findCoutRevient: (magasinId: string, articleId: string, quantite: any) => {
    return requestGet<Record<string, any>>(
      `${magasinArticle.getBase()}/${magasinId}/${articleId}/prix-revient?quantite=${quantite}`
    );
  },
};

// Approvisionnement
const approvisionnement = new CrudService<
  ApprovisionnementResource,
  ApprovisionnementResource
>("approvisionnements");
export const approvisionnementService = {
  ...approvisionnement,
  valider: (id: string) => {
    return requestPut<{ data: ApprovisionnementResource }>(
      `${approvisionnement.getBase()}/${id}/validate`
    );
  },
};

// inventaires
const inventaire = new CrudService<InventaireResource, InventaireResource>(
  "inventaires"
);
export const inventaireService = {
  ...inventaire,
  valider: (inventaireId: string) => {
    return requestPut<{ data: InventaireResource }>(
      `${inventaire.getBase()}/${inventaireId}/validate`
    );
  },
};

// Vente
const vente = new CrudService<VenteResource, VenteResource>("ventes");
export const venteService = {
  ...vente,
  valider: (id: string) => {
    return requestPut<{ data: VenteResource }>(
      `${vente.getBase()}/${id}/validate`
    );
  },
  livrer: (id: string, body: any) => {
    return requestPut<{ data: { vente: VenteResource } }>(
      `${vente.getBase()}/${id}/livrer`,
      body
    );
  },
};

// Vente
const recus = new CrudService<RecuResource, RecuResource>("recus");
export const recusService = { ...recus };

// Paiement sur site

const paiement = new CrudService<PaiementResource, PaiementResource>(
  "paiements"
);
export const paiementService = {
  ...paiement,

  valider: (paiementId: string) => {
    return requestPut<{ data: PaiementResource }>(
      `${paiement.getBase()}/${paiementId}/valider`
    );
  },

  finaliser: (paiementId: string, body: any) => {
    return requestPut<{ data: { paiement: PaiementResource } }>(
      `${paiement.getBase()}/${paiementId}/finaliser`,
      body
    );
  },

  addBeneficiairesPaiements: (paiementId: string, body: any[]) => {
    return requestPost<{ data: PaiementResource }>(
      `${paiement.getBase()}/${paiementId}/beneficiaires-paiements`,
      body
    );
  },
};

// Beneficiaire Paiement

const beneficiairePaiement = new CrudService<
  BeneficiairePaiementResource,
  BeneficiairePaiementResource
>("beneficiairepaiements");
export const beneficiairePaiementService = {
  ...beneficiairePaiement,
  realiser: (beneficiairePaiementId: string, body: any) => {
    return requestPut<{ data: BeneficiairePaiementResource }>(
      `${beneficiairePaiement.getBase()}/${beneficiairePaiementId}/realiser`,
      body
    ).then(({ data }) => data);
  },
  annuler: (beneficiairePaiementId: string, body: any) => {
    return requestPut<{ data: BeneficiairePaiementResource }>(
      `${beneficiairePaiement.getBase()}/${beneficiairePaiementId}/annuler`,
      body
    ).then(({ data }) => data);
  },
  addTemoin: (beneficiairePaiementId: string, body: any) => {
    return requestPost<{ data: BeneficiairePaiementResource }>(
      `${beneficiairePaiement.getBase()}/${beneficiairePaiementId}/temoins`,
      body
    ).then(({ data }) => data);
  },
};

const beneficiaire = new CrudService<
  BeneficiaireResource,
  BeneficiaireResource
>("beneficiaires");
export const beneficiaireservice = {
  ...beneficiaire,
  findBeneficiaires: (body: any) => {
    return requestPost<{ data: any[] }>(
      `${beneficiaire.getBase()}/findBeneficiaires`,
      body
    ).then(({ data }) => data);
  },
};
