import { Typography, Stack, CircularProgress } from "@mui/material";

import logo from "assets/images/logo.png";
import { FC } from "react";

type FullLoadingProps = {
  withHeader?: boolean;
  withLogo?: boolean;
};
export const FullLoading: FC<FullLoadingProps> = ({
  withHeader = true,
  withLogo = true,
}) => {
  return (
    <Stack
      sx={{
        height: `calc(100vh - ${withHeader ? "120px" : "0"})`,
        width: "100%",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack justifyContent="center" alignItems="center">
        {withLogo && <img width="65px" height="50px" src={logo} alt="Burval" />}
        <Stack flexDirection="row" alignItems="center" marginTop={0.75}>
          <CircularProgress
            color="primary"
            size={20}
            sx={{ marginRight: "0.25rem" }}
          />
          <Typography fontSize="1rem">chargement ...</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
