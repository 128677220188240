import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SelectItem } from "types/form.type";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type SelectInputProps = {
  items: SelectItem[];
  label: string;
  name: string;
  multiple?: boolean;
};

export const SelectInput: FC<SelectInputProps> = ({
  items,
  label,
  name,
  multiple,
}) => {
  const [personName, setPersonName] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        size="small"
        multiple={multiple}
        value={personName}
        onChange={handleChange}
        renderValue={
          multiple
            ? (selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    margin: 0,
                  }}
                >
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        items.find((i) => i.value === value)?.label || value
                      }
                    />
                  ))}
                </Box>
              )
            : undefined
        }
      >
        {!multiple && (
          <MenuItem value="">
            <i>(aucun)</i>
          </MenuItem>
        )}
        {items.map((item, i) => (
          <MenuItem
            sx={{
              whiteSpace: "unset",
              paddingY: 1,
              "&.Mui-selected": {
                bgcolor: (theme) => theme.palette.primary.lighter,
                "&:hover": {
                  bgcolor: (theme) => theme.palette.primary.lighter,
                },
              },
              "&:hover": {
                backgroundColor: "inherent",
              },
              marginBottom: 0.25,
            }}
            key={item.value}
            value={item.value}
            divider={i !== items.length - 1}

            // style={getStyles(name, personName, theme)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {/* {errors[name] && (
        <FormHelperText error>
          {errors[name]?.message?.toString()}
        </FormHelperText>
      )} */}
    </FormControl>
  );
};

type SelectMultipleFormProps = SelectInputProps & {};

export const SelectMultipleForm: FC<SelectMultipleFormProps> = ({
  items,
  label,
  name,
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setValue(name, value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        size="small"
        multiple
        value={watch(name) || []}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              margin: 0,
            }}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                label={items.find((i) => i.value === value)?.label || value}
              />
            ))}
          </Box>
        )}
      >
        {items.map((item, i) => (
          <MenuItem
            sx={{
              whiteSpace: "unset",
              paddingY: 1,
              "&.Mui-selected": {
                bgcolor: (theme) => theme.palette.primary.lighter,
                "&:hover": {
                  bgcolor: (theme) => theme.palette.primary.lighter,
                },
              },
              "&:hover": {
                backgroundColor: "inherent",
              },
              marginBottom: 0.25,
            }}
            key={item.value}
            value={item.value}
            divider={i !== items.length - 1}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {errors[name] && (
        <FormHelperText error>
          {errors[name]?.message?.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );
};

type SimpleSelectProps = {
  items: SelectItem[];
  selected: SelectItem[];
  label: string;
  name: string;
  onChange: (e: SelectChangeEvent<string>) => void;
};

export const SimpleSelect: FC<SimpleSelectProps> = ({
  label,
  name,
  onChange,
  items,
  selected,
}) => {
  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select value={""} MenuProps={MenuProps}>
        <MenuItem value="">
          <i>(aucun)</i>
        </MenuItem>
        {items.map((item) => (
          <MenuItem
            sx={{ whiteSpace: "unset", paddingY: 1 }}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
