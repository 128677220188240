import { Grid } from "@mui/material";
import { TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";

import * as yup from "yup";
import { UtilitaireModal } from "../UtilitaireModal";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Gestion des secteurs d'activités"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput label="Nom *" name="libelle" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code *" name="code" />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const SecteurActiviteModal = withForm(
  Form,
  yup.object({
    libelle: yup.string().required(),
    code: yup.string().required(),
  })
);
