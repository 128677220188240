import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListPaginated } from "types/list.type";
import { Notification, NotificationResource } from "types/message.type";

interface MessageState extends Notification {
  severity?: "error" | "success" | "info" | "warning";
  show: boolean;
  loading: boolean;
  notifications: ListPaginated<NotificationResource>;
  loadingNotifications: boolean;
}

const initialState: MessageState = {
  show: false,
  loading: false,
  notifications: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  loadingNotifications: false,
};

const alert = (state: MessageState, action: PayloadAction<Notification>) => {
  state.message = action.payload.message;
  state.title = action.payload.title;
  state.show = true;
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    alertSuccess: (state, action: PayloadAction<Notification>) => {
      alert(state, action);
      state.severity = "success";
    },
    alertError: (state, action: PayloadAction<Notification>) => {
      alert(state, action);
      state.severity = "error";
    },
    alertInfo: (state, action: PayloadAction<Notification>) => {
      alert(state, action);
      state.severity = "info";
    },
    alertWarning: (state, action: PayloadAction<Notification>) => {
      alert(state, action);
      state.severity = "warning";
    },
    closeAlert: (state) => {
      state.show = false;
    },
    showLoader: (state) => {
      state.loading = true;
    },
    closeLoader: (state) => {
      state.loading = false;
    },
    setNotifications(state, action: PayloadAction<ListPaginated<any>>) {
      state.notifications = action.payload;
    },
    setLoadingNotifications(state, action: PayloadAction<boolean>) {
      state.loadingNotifications = action.payload;
    },
    updateNotification(state, action: PayloadAction<NotificationResource>) {
      const index = state.notifications.data.findIndex(
        (n) => n.id === action.payload.id
      );
      if (index > -1) {
        state.notifications.data[index] = action.payload;
      }
    },
  },
});

export const {
  alertError,
  alertInfo,
  alertSuccess,
  alertWarning,
  closeAlert,
  closeLoader,
  showLoader,
  setNotifications,
  setLoadingNotifications,
  updateNotification,
} = notificationSlice.actions;

const getMessageReducer = (state: any): MessageState => state.message;

export const selectNotification = createSelector(
  getMessageReducer,
  (state) => state
);

export const selectShowLoader = createSelector(
  getMessageReducer,
  (state) => state.loading
);

export const selectNotifications = createSelector(
  getMessageReducer,
  (state) => state.notifications
);

export const selectLoadingNotifications = createSelector(
  getMessageReducer,
  (state) => state.loadingNotifications
);

export default notificationSlice.reducer;
