import { Table, TableRow } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import { useEffect, useState } from "react";
import { reportingService } from "services";

import { ThousandText } from "components";
import { useSelector } from "react-redux";
import { selectChiffreAffaireFilter } from "reducers";
import { dateFormater } from "utils/date";
import { ReportingCell } from "../common";
import { Loader } from "components/Loader";

export const ChiffreAffaireParClient = () => {
  const [data, setData] = useState<any[]>([]);
  const [titles, setTitles] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const filter = useSelector(selectChiffreAffaireFilter);

  useEffect(() => {
    const { type, periode } = filter;

    if ([type, periode].some((e) => !e)) {
      setLoading(false);
      return;
    }
    const params: Record<string, any> = {
      type: type.value,
      periode: periode.value,
    };

    if (periode.value === "mensuel") {
      params.mois = dateFormater.getMonth(filter.mois);
      params.annee = dateFormater.getYear(filter.annee);
    } else if (periode.value === "hebdo") {
      params.dateDebut = dateFormater.toBackPattern(
        filter.dateDebut,
        "yyyy-MM-dd"
      );
      params.dateFin = dateFormater.toBackPattern(filter.dateFin, "yyyy-MM-dd");
    } else {
      params.dateDebut = dateFormater.toBackPattern(filter.date, "yyyy-MM-dd");
      params.dateFin = dateFormater.toBackPattern(filter.date, "yyyy-MM-dd");
    }

    reportingService
      .findChiffreAffaireByClients(params)
      .then(({ clients, titles }) => {
        setData(clients);
        setTitles(titles);
        // console.log(clients);
      })
      .finally(() => setLoading(false));
  }, [filter]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Table sx={{ th: { fontWeight: 600, textAlign: "center" } }}>
        <TableHead>
          <TableRow>
            <ReportingCell rowSpan={2}>Clients</ReportingCell>
            <ReportingCell colSpan={2}>{titles.previous}</ReportingCell>
            <ReportingCell colSpan={2}>{titles.current}</ReportingCell>
            <ReportingCell colSpan={2}>Variation</ReportingCell>
          </TableRow>
          <TableRow>
            <ReportingCell>Mois</ReportingCell>
            <ReportingCell>Cumul mois</ReportingCell>
            <ReportingCell>Mois</ReportingCell>
            <ReportingCell>Cumul du mois</ReportingCell>
            <ReportingCell>Mois</ReportingCell>
            <ReportingCell>Cumul du mois</ReportingCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRow key={item.client.id}>
                <ReportingCell>{item.client.nom}</ReportingCell>
                <ReportingCell>
                  <ThousandText value={item.previous.montant} />
                </ReportingCell>
                <ReportingCell>
                  <ThousandText value={item.previous.montantCumul} />
                </ReportingCell>
                <ReportingCell>
                  <ThousandText value={item.current.montant} />
                </ReportingCell>
                <ReportingCell>
                  <ThousandText value={item.current.montantCumul} />
                </ReportingCell>
                <ReportingCell>{item?.variation?.mois}</ReportingCell>
                <ReportingCell>{item?.variation?.cumul}</ReportingCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
