// material-ui
import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[700],
          fontSize: "0.85rem",
          fontWeight: 500,
          "&.MuiInputLabel-sizeSmall": {
            paddingTop: "5px",
          },
        },
        outlined: {
          lineHeight: "1rem",
          "&.MuiInputLabel-sizeSmall": {
            "&.MuiInputLabel-shrink": {
              top: "-2px",
              marginLeft: "-5px",
              fontSize: "1rem",
              color: "#838896",
            },
          },
          "&.MuiInputLabel-shrink": {
            background: theme.palette.background.paper,
            fontWeight: 500,
            color: "#838896",
            fontSize: "1rem",
          },
        },
      },
    },
  };
}
