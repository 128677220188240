import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { centreService } from "services";

import { CentreResource } from "types/centre.type";
import { useApp, useModal, useUser } from "hooks";
import { CentreModal } from "./CentreModal";
import { selectHelper } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";

const ListCentre = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<CentreResource[]>([]);
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const modal = useModal();
  const { paysId } = useUser();
  const { centres } = useApp();
  const queryClient = useQueryClient();

  useEffect(() => {
    setLoading(true);
    centres
      .fetchData()
      .then((resultat) => {
        setData(resultat);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [centres, paysId]);

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onSave = (data: any) => {
    const { centreRegional, ...rest } = data;
    const body = {
      ...rest,
      centreregionalId: selectHelper.getValue(data.centreRegional),
    };

    if (defaultValues?.id) {
      return centreService.update(defaultValues.id, body);
    }
    return centreService.create(body);
  };

  const onEdit = (original: CentreResource) => {
    setDefaultValues({
      id: original.id,
      libelle: original.libelle,
      code: original.code,
      centreRegional: selectHelper.buildCentreRegional(original.centreRegional),
    });
    modal.show();
  };

  const onFinished = (element: CentreResource) => {
    centres.update(element);
    centres.fetchData().then(setData);
    queryClient.invalidateQueries(["centres"]);
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.code || "-"}</Typography>
        ),
      },
      {
        Header: "Centre regional",
        accessor: "centreRegional.libelle",
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier le centre">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Centres"
        subtitle="Consulter et gérer les centres"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={modal.show}>
            Créer un centre
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        loading={loading}
        withPagination
      />
      {modal.open && (
        <CentreModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={onSave}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListCentre;
